import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Offline, Online } from 'react-detect-offline'
import BarcodeReader from 'react-barcode-reader'
import ProductService from '../../../api/ProductAPI'
import Clock from 'react-live-clock'
import { useLocation } from 'react-router'
import * as commonActions from '../../../state/common/commonActions'
import * as stockActions from '../../../state/stock/stockActions'
import KeyboardIcon from '../../../assets/svgs/KeyboardIcon'
import useOrders from '../../../hooks/useOrders'
import FullScreenLoader from '../../../common/components/FullScreenLoader'
import layouts, { defaultName } from '../../../utils/keyboardLayouts'

const Navbar = ({ title, entryType = 'in' }) => {
	const user = useSelector((state) => state.user);
	return (
		<>
			<div className="bg-1 navbar-1">

				<div className="search-bar">
				</div>

				<h3 style={{ color: 'var(--white)', marginLeft: '-15%' }}>{`${title}`}</h3>

				<div className="user-detail ">
					<div className="column justify-around">
						<p className="text-5 align-center justify-center">
							{' '}
							<Clock ticking={true} format={' MMMM D, YYYY, h:mm:ss A'} />{' '}
						</p>

						<div className="text-5 row align-center justify-between" style={{ fontWeight: 'bold' }}>
							<div className="text-5 align-center justify-center" style={{ fontWeight: 'bold' }}>
								{' '}
								{user?.data?.name}&ensp;
							</div>
							<div className="text-5 align-center justify-center" style={{ fontWeight: 'bold', flexDirection: 'row' }}>
								<Online>
									<span style={{ fontSize: 22, color: '#49ff49', display: 'inline-block' }}>&#x25cf;</span>
								</Online>
								<Offline>
									<span style={{ fontSize: 22, color: '#000', display: 'inline-block' }}>&#x25cf;</span>
								</Offline>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Navbar
