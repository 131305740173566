import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import * as invoiceActions from "../../state/invoice/invoiceActions";
import * as Hangul from "hangul-js"
import { Link } from "react-router-dom";
import InvoiceService from "../../api/InvoiceAPI";
import InvoiceModal from "./components/InvoiceModal";
import { FaArrowLeft } from "react-icons/fa";
import CommonModal from "../../common/components/CommonModal";
import Motion1 from "../../common/components/Motion1";
import { Keyboard } from "react-touch-screen-keyboard";
import layouts, { defaultName } from "../../utils/keyboardLayouts";
import invoicesAPI from "../../api/InvoiceAPI";

function ReprintPageContainer() {
  const children = <h1>Hello</h1>;

  // Load Suspend Data
  const suspend = useSelector((state) => state.suspend);
  const order = useSelector((state) => state.order);
  const user = useSelector((state) => state.user);
  const invoice = useSelector((state) => state.invoice);


  const [text, setText] = useState("");
  const [from, setFrom] = useState(new Date().toISOString().split('T')[0]);
  const [to, setTo] = useState(new Date().toISOString().split('T')[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [invoiceId, setInvoiceId] = useState("")
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [dateTime, setDateTime] = useState("")
  const [editDateTime, setEditDateTime] = useState(false)


  const [activeField, setActiveField] = useState("i");

  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default")

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };

  const saveInvoice = async () => {
    try {

      const data = await invoicesAPI.updateInvoice({
        invoice_id: selected.id,
        date:date!="" ?date:"-1",
        time:time!="" ?time:"-1",
        uniq_id: invoiceId
      });

      if (data.success) {
        toggleEdit()
        getInvoices();
      }
      else {
        alert("This invoice id has already been used")
      }


    } catch (error) {

      alert("This invoice id has already been used")
    }
  }


  const renderEdit = () => {
    return (
      <div className="column" style={{ marginBottom: 60 }}>
        <div className="row justify-between">
          <div className="text-1" style={{ fontSize: 22 }}>
            Invoice
          </div>
          <input
            type="text"
            value={invoiceId}
            onChange={e => setInvoiceId(e.target.value)}
            onClick={() => {
              setInvoiceId("");
              setActiveField('i')
            }}
            style={{ textAlign: "left" }}
            className={`numpad-input-3 ${activeField == "n" ? 'active-field' : ''}`}
          />
        </div>
        <div className="row mb-1">
          
        <div     style={{ padding: 10, marginLeft: 20, height: 40, color: "#fff" }}>
          {dateTime}
          </div>
          <div
          className="btn btn-sm bg-1 text-white "
          onClick={() => {
           setEditDateTime(!editDateTime)
          }}
          style={{ padding: 10, marginLeft: 20, height: 40, color: "#fff" }}
        >
          Edit Date
        </div>

        </div>
        {editDateTime ?
          <>
            <div className="row justify-between">
              <div className="text-1" style={{ fontSize: 22 }}>
                Date
              </div>
              <input
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
                onClick={() => {
                  setDate("");
                  setActiveField("d")
                }}
                style={{ textAlign: "left" }}
                className={`numpad-input-3 ${activeField == "p" ? 'active-field' : ''}`}
              />
            </div>
            <div className="row justify-between">
              <div className="text-1" style={{ fontSize: 22 }}>
                Time
              </div>
              <input
                type="time"
                value={time}
                onChange={e => setTime(e.target.value)}
                onClick={() => {
                  setTime("");
                  setActiveField("t")
                }}
                style={{ textAlign: "left" }}
                className={`numpad-input-3 ${activeField == "p" ? 'active-field' : ''}`}
              />

            </div>
          </>
          : null}
        

      </div>

    );
  };

  useEffect(() => {
    setText("")
  }, [from, to])


  const dispatch = useDispatch();

  useEffect(() => {
    if (suspend?.data?.length > 0) {
      const activeSuspend = suspend?.data?.find(
        (e) => e.id === suspend?.activeId
      );
      if (activeSuspend) {
        dispatch(orderActions.fillOrder(activeSuspend?.order));
        dispatch(customerActions.fillCustomer(activeSuspend?.customer));
        dispatch(suspendActions.deleteSuspend(suspend?.activeId));
      }
    }
  }, [suspend.activeId]);

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {

    if (show && selected) {
      setInvoiceId(selected?.uniq_id)
      
      let dateNew = new Intl.DateTimeFormat('en-CA', {
        dateStyle: 'short', timeStyle: 'short', hourCycle: "h24",
      }).format(new Date(selected?.created_at))

      setDateTime(dateNew)
    } else {
      setInvoiceId("")
      setDate("")
      setTime("")
    }
  }, [show])


  const nextProducts = async (query = '') => {
    try {
      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id, user?.data?.id, null, currentPage + 1);
      const data = data2?.data?.invoices;

      console.log(data);
      if (data?.data?.length > 0) {
        setCurrentPage(data.current_page);
        setInvoices(data.data);
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }

  const prevProducts = async (query = '') => {
    try {

      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id, user?.data?.id, null, currentPage - 1);
      const data = data2?.data?.invoices;
      console.log(data);
      if (data?.data?.length > 0) {
        setCurrentPage(data.current_page);
        setInvoices(data.data);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

    }

  }





  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async (query = text) => {
    setSelected(null)
    dispatch(invoiceActions.setActiveInvoice(null))
    try {
      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices(query, from, to, null, null, user?.data?.branch?.id, user?.data?.id, null, 1);
      const data = data2.data.invoices;
      console.log(data);
      if (data.data.length > 0) {
        setInvoices(data.data);
      } else {
        setInvoices([])
      }
      setIsLoading(false)

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };
  const [selected, setSelected] = useState(null);

  const [showInvoice, setShowInvoice] = useState(false);

  const toggleInvoice = () => {
    setShowInvoice(!showInvoice)
  }

  const toggleEdit = () => {
    setShow(!show)
  }



  return (
    <div className="home-container bg-10">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Reprint</h3>
      </div>
      <div className="modal-actions-lg row justify-center">
        {invoices.length > 0 && invoice?.activeInvoice && <div onClick={() => {
          toggleInvoice()
          setTimeout(() => {
            window.print();
          }, 100);
        }} className="modal-btn-lg bg-6 text-1">
          Print
        </div>
        }
        <Link to="/home" className="modal-btn-lg row justify-center align-center bg-1 text-1">
          <FaArrowLeft color="#fff" /> &nbsp; Back
        </Link>

      </div>
      <Navbar next={nextProducts} prev={prevProducts} getInvoices={getInvoices} text={text} setText={setText} toggleEdit={toggleEdit} to={to} from={from} setTo={setTo} setFrom={setFrom} />
      <ProductsTable
        selected={selected}
        setSelected={setSelected}
        invoices={invoices}
        isLoading={isLoading}
      />
      <InvoiceModal toggle={toggleInvoice} invoice={selected} show={showInvoice} reprint={true} />
      <CommonModal

        children={renderEdit}
        isSaving={isSaving}
        show={show}
        title_1="Save"
        onOk={!isSaving ? saveInvoice : () => { }}
        toggleModal={toggleEdit}
      />
    </div>
  );
}

export default ReprintPageContainer;
