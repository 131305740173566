import axios from "axios";

const invoicesAPI = {
  getAllInvoices: async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_TAX_URL_API + "/invoices");
      return response.data;
    } catch (error) {
      throw error;
    }
  },


  searchInvoices: async (id,from,to,startTime,endTime,branch_id,user_id,return_status,page,limit=50) => {
   

    console.log(startTime);
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API
    let url;

    if(id){

      url = `${process.env.REACT_APP_TAX_URL_API}/search/invoices?id=${id ?? ''}&branch_id=${branch_id ?? ''}&user_id=${user_id ?? ''}&limit=${limit ?? 50}&page=${page ?? 1}`;
    }else{

      url = `${process.env.REACT_APP_TAX_URL_API}/search/invoices?from=${from ?? ''}&to=${to ?? ''}&startTime=${startTime ?? "00:00:00"}&endTime=${endTime ?? "23:59:59"}&return=${return_status ?? ''}&branch_id=${branch_id ?? ''}&user_id=${user_id ?? ''}&limit=${limit ?? 50}&page=${page ?? 1}`;
    }

    console.log(url);
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  returnInvoice: async (id) => {
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API
    let url;
    if (id) {
      url = process.env.REACT_APP_TAX_URL_API + "/return_invoice?id=" + id;
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  },

  manualReturnInvoice: async (data) => {
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API
    let url;
    if (data) {
      url = process.env.REACT_APP_TAX_URL_API + "/manual_return_invoice";
      try {
        const headers = {
          "Content-Type" :"application/json",
          "accept" :"application/json",
        }
        const response = await axios.post(url,data,headers);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  },

  getInvoiceCode: async ({branch=null,returnI=null,user_id=null}) => {
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API
    let url;
      url = `${process.env.REACT_APP_TAX_URL_API}/get_invoice_code?branch=${branch}&return=${returnI}&user_id=${user_id}`;
      console.log(url);
      try {
       
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw error;
      }
    
  },
  updateInvoice: async (data) => {
    // TODO: -> Optimize by Axios Token
    // TODO: -> Thunk Implimentation
    // TODO: -> Data Validation and Manipulation
    // TODO: -> Error Handling Of API
    let url;
    if (data) {
      url = process.env.REACT_APP_TAX_URL_API + "/update-invoice";
      try {
        const headers = {
          "Content-Type" :"application/json",
          "accept" :"application/json",
        }
        const response = await axios.post(url,data,headers);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  },
};

export default invoicesAPI;
