import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import StockService from '../../../api/StockAPI'
import ProductRow from './ProductRow'
import { Pagination } from 'react-laravel-paginex'
import LaravelPagination from '../../../common/components/LaravelPagination'

const ProductTable = ({ entryType = 'in' }) => {
	const dispatch = useDispatch()
	const [productsPaginateData, setProductsPaginateData] = React.useState([])
	const [loading, setLoading] = React.useState(true)
	const [search, setSearch] = React.useState('')
	const user = useSelector((state) => state.user)
	const stock = useSelector((state) => state.stock)

	const fetchProducts = async (query) => {
		setLoading(true)
		const response = await StockService.getLogProducts({ ...query, user_id: user?.data?.id, with: 'logs' })
		if (!response?.data?.length) return
		setProductsPaginateData(response)
		setLoading(false)
	}
	React.useEffect(() => {
		fetchProducts()
	}, [])
	const handleSearch = (e) => {
		setSearch(e.target.value)
	}

	// JSX
	return (
		<div>
			{stock?.products?.length ? (
				<ProductRow key={stock?.products[0]?.id} item={stock?.products[0]} />
			) : productsPaginateData?.data?.length > 0 ? (
				<>
					{/* <ProductDiv>Total Number of Products: {productsPaginateData.length}</ProductDiv> */}
					{productsPaginateData?.data
						?.map((product, index) => {
							return <ProductRow key={product?.id} item={product} />
						})
						.reverse()}
				</>
			) : (
				<h2 style={{ textAlign: 'center', margin: 20 }}>No Product Found</h2>
			)}
			{!stock?.products?.length && (
				<M20>
					<LaravelPagination changePage={fetchProducts} data={productsPaginateData} />
				</M20>
			)}
		</div>
	)
}

const ProductDiv = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: #ecfc91;
	margin: 0px 20px;
	justify-content: center;
	/* cursor: pointer; */
	margin-top: 10px;
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`
const M20 = styled.div`
	margin: 20px;
`
const styles = {
	input: { height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 },
}

export default ProductTable
