import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import Motion1 from '../../../common/components/Motion1'
import { capitalize } from 'lodash'

const ProductRow = ({ item, name, index = 1, header = false }) => {
	return (
		<>
			<Motion1>
				<ProductItem isHeader={header} item={item}>
					<div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
						<h5 style={{ width: '10%', textAlign: 'center' }}>{capitalize(name)} </h5>
					 	<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.updated_at == 'Last Updated Date' ? item?.updated_at : moment(item?.updated_at).format('D-M-Y')} </h5>
						<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.stock_out_reason || 'N/A'} </h5>
						 <h5 style={{ width: '10%', textAlign: 'center' }}>{item?.stock} </h5> 
						<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.expiry_date} </h5>
					 	<h5 style={{ width: '10%', textAlign: 'center' }}>{capitalize(item?.entry_type)} </h5>
						{/* <h5 style={{ width: '10%', textAlign: 'center' }}>{(item?.is_return === 'N' ? 'No' : (item?.is_return == 'Y' ? 'Yes' : item?.is_return))} </h5> */}
					</div>
				</ProductItem>
			</Motion1>
		</>
	)
}

const ProductItem = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: ${({ isHeader, item }) => (isHeader ? '#fff' : item?.entry_type === 'out' ? '#ffcccb' : item?.entry_type === 'manual' ? '#ecfc91' : '#87f8c9')};
	margin: 10px 20px;
	justify-content: center;
	/* cursor: pointer; */
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	padding-top: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	padding-bottom: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--red-2);
	text-decoration: none;
	color: white;
	padding: 16px 26px;
	width: fit-content;
	white-space: nowrap;
	margin: 4px;
	border-radius: 4px;
`

export default ProductRow
