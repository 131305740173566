const initialState = [];


const SET_BRANCHES = 'SET_BRANCHES';


const branchReducer =(state =initialState,action) => {
    switch (action.type) {
        case SET_BRANCHES:    
            return action.payload
            default:
            break;
        }
          return state;
}

export default branchReducer;
