import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch,useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as commonActions from "../../../state/common/commonActions";
import * as offlineActions from "../../../state/offline/offlineActions";
import * as invoiceActions from "../../../state/invoice/invoiceActions";
import BarcodeReader from "react-barcode-reader";
import ProductService from "../../../api/ProductAPI";
import OrderService from "../../../api/OrderAPI";
import InvoiceService from "../../../api/InvoiceAPI";
import { getTens, n_format, n_round, togglePOSFullScreen } from "../../../utils/common";

function ConfirmModal({ show, toggle ,toggleInvoice,returnId,getInvoices}) {
 
  const order = useSelector(state => state.order);
  const common = useSelector(state => state.common);
  const customer = useSelector(state => state.customer);
  const user = useSelector(state => state.user)
  const suspend = useSelector(state => state.suspend);
  const offline = useSelector(state => state.offline);
  const invoice = useSelector(state => state.invoice)

  const KHR_RATE = common?.currencies[0];
  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);
  const [isSaving, setIsSaving] = useState(false)
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    setOrderAmount();
  }, [order.products, show])


  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = +pureQuantity + +e?.quantity;
      purePoints = +purePoints + (+e?.quantity * e?.price * (e?.point || 0)) / 100;

    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount) - n_format(discount));
  };

  const onOk = () => {
    // On CLosed
    // togglePOSFullScreen(true);s
    toggleInvoice();
    setTimeout(() => {
      window.print()
    }, 100);

  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     toggleInvoice();
  //     window.print();
  //   }, 100);
  // }, [])
  

  const back = async () => {
    // togglePOSFullScreen(true);
    if(!isSaving){
    try {
  
    setIsSaving(true)
    // const uniq_id = await InvoiceService.getInvoiceCode({branch:user?.data?.branch?.id});
    const invoiceId =(user?.data?.inv_code ?? "") +""+ user?.data?.branch?.code + "" + (+invoice?.lastInvoiceId?.split(user?.data?.branch?.code)[1]+ +1);
     
   
    // TODO Reset and then back
    const data = {
      order: order,
      customer: customer.data,
      user_id: user?.data?.id,
      amount,
      redeemAmount:order?.invoice?.redeemAmount,
      currency: common?.current_currency,
      orderPoints,
      payBy:order?.invoice?.payBy,
      branchId:user?.data?.branch?.id,
      invoiceId:invoice?.lastInvoiceId,
      dinomAmount:order?.invoice?.dinomAmount
    };


    // TODO: Find Why You Call return Invoice twice
    if(order?.invoice?.return){
      await InvoiceService.returnInvoice(suspend?.activeId);
    }

    if(returnId){
      await InvoiceService.returnInvoice(returnId);
      getInvoices();
      setIsSaving(false)
      toggle();
    }else{
     
        // const response = await OrderService.addOrder(data);
        // if (response.success) {
        //   // if(true) {
            if(common?.trashActive)
            dispatch(commonActions.toggleTrash())
            dispatch(orderActions.emptyOrder());
            dispatch(customerActions.emptyCustomer());
            dispatch(invoiceActions.setLatestInvoiceId(invoiceId));
            console.log(invoice.lastInvoiceId,"Old Generated");
            setIsSaving(false)
            toggle();
          // }
        }
      } catch (error) {
        setIsSaving(true)
        // console.log(invoice.lastInvoiceId,"New Generated");
        const invoiceId = (user?.data?.inv_code ?? "") +""+user?.data?.branch?.code + "" + (+invoice?.lastInvoiceId?.split(user?.data?.branch?.code)[1]+ +1);
        // console.log(invoiceId,"New Generated");
        // const data = {
        //   order: order,
        //   customer: customer.data,
        //   user_id: user?.data?.id,
        //   amount,
        //   redeemAmount:order?.invoice?.redeemAmount,
        //   currency: common?.current_currency,
        //   orderPoints,
        //   payBy:order?.invoice?.payBy,
        //   branchId:user?.data?.branch?.id,
        //   invoiceId:invoice?.lastInvoiceId,
        //   dinomAmount:order?.invoice?.dinomAmount
        // };
        dispatch(invoiceActions.setLatestInvoiceId(invoiceId));
        // console.log(invoice.lastInvoiceId,"Setted");
        // dispatch(offlineActions.addOfflineInvoice({
        //   id:offline?.invoices?.length +1,
        //   data
        // }));

        // console.log(offline?.invoices,"dskjfndksnflksmlml");


        if(common?.trashActive)
            dispatch(commonActions.toggleTrash())
            dispatch(orderActions.emptyOrder());
            dispatch(customerActions.emptyCustomer());
            setIsSaving(false)
            toggle();
      }
    }
  };

  const amount = order?.invoice?.redeemAmount ? calAmount - order?.invoice?.redeemAmount : calAmount


  const renderModal = () => {
    return (
      <div className="column" style={{paddingBottom:40}}>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Net Total:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ${n_format(amount)}
          </div>
        </div>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Recieve:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            ${n_format(order?.invoice?.dinomAmount)}
          </div>
        </div>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Change:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
          ${n_format(+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount)}
          </div>
        </div>
        {customer?.data?.phone ?
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Reward Points:
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
            {n_format(orderPoints)}
          </div>
        </div>
        :null}
        {/* <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 13 }}>
            Riel Below $100 : $10 X $ {n_format(getTens((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount)))}
          </div>
          <div className="text-1" style={{ fontSize: 13 }}>
          ៛{" "}{n_format(getTens
                      ((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount)) * USD_RATE?.value
                    )}
          </div>
        </div> */}
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Riel Below $10 : $ {n_format(((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount) % 10))}
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
          ៛{" "}{n_round(n_format((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount) % 10) * parseFloat(order?.invoice?.currency_rate ?? USD_RATE?.value))}
          </div>
        </div>
        <div className="row justify-between" style={{ marginTop: 10 }}>
          <div className="text-1" style={{ fontSize: 15 }}>
            Riel Below $1 : $ {n_format((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount) % 1)}
          </div>
          <div className="text-1" style={{ fontSize: 15 }}>
        
                  {USD_RATE?.symbol}{" "}
                  {n_round(n_format((+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount) % 1) *
                      parseFloat(order?.invoice?.currency_rate ?? USD_RATE?.value)
                  )}
          </div>
        </div>
        <div
          className="column align-center justify-center"
          style={{ marginTop: 50 }}
        >
          <div className="text-1" style={{ fontSize: 15 }}>
            Compute change for (USD Dollar):
          </div>
          <input
            type="text"
            className="w-100"
            value={"$ "+n_format(+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount)}
            style={{marginBottom:10,padding:6,fontSize:25,fontWeight:"bold",fontFamily:"Arail",textAlign:"right"}}
          />
        </div>
        <div className="w-100  justify-between row">
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">CODE</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">Description</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-1">
              <span className="text-1 ">Amount</span>
            </div>
          </div>
        </div>
        <div className="w-100  justify-between row">
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">KHR</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">Khmer Riel</span>
            </div>
          </div>
          <div className="w-100">
            <div className="btn t-header row justify-center align-center t-center bg-6">
              <span className="text-1 ">{n_round(n_format(+order?.invoice?.dinomAmount - +calAmount + +order?.invoice?.redeemAmount) * parseFloat(order?.invoice?.currency_rate ?? USD_RATE?.value))}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <CommonModal
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      isSaving={isSaving}
      title_1="Print"
      header="Change Information"
      title_2="Close"
      cancel
      back={false}
      handleBack={()=>{
        // togglePOSFullScreen(true);
        toggle();
      }}
      toggleModal={back}
    />
  );
}

export default ConfirmModal;
