import React from 'react'
import { useParams } from 'react-router-dom'
import Navbar from './components/Navbar'
import ProductRow from './components/ProductRow'
import ProductTable from './components/ProductTable'
import Footer from './components/Footer'
import { t } from '../../utils/common'

function StockLogContainer() {
	let { product_id } = useParams()
	console.log({ product_id })
	return (
		<div
			style={{
				marginBottom: '100px',
			}}
		>
			<Navbar title={'Product Stock Entries'} entryType={'in'} />
			<ProductRow
				header={true}
				name={t('Product')}
				item={{
					updated_at: t('Last Updated Date'),
					stock_out_reason: t('Reason'),
					stock: t('Stock'),
					send_to: t('Send To'),
					expiry_date: t('Expiry Date'),
					entry_type: t('Entry Type'),
	
				}}
			/>
			<ProductTable product_id={product_id} />
			<Footer />
		</div>
	)
}

export default StockLogContainer
