import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import { n_format, n_round } from "../../../utils/common";
import "../../../invoice.css";
import InvoiceService from "../../../api/InvoiceAPI";


function InvoiceModal({ show, toggle }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);
  const invoice = useSelector(state => state.invoice)

  const KHR_RATE = common?.currencies[0];
  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);
  const [uniqId, setUniqId] = useState("")

  const dispatch = useDispatch();

  useEffect(() => {
    setOrderAmount();
  }, [order.products, show]);

  const setOrderAmount = async () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = pureTotalAmount + total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = pureQuantity + e?.quantity;
      purePoints = +purePoints + (e?.quantity * e?.price * (e?.point || 0)) / 100;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount));
    console.log(invoice?.lastInvoiceId,"oops",);
    const invoiceId = invoice?.lastInvoiceId;

    setUniqId(invoiceId)
  };

  const onOk = () => {
    // On CLosed
    back();
  };

  const back = () => {
    // TODO Reset and then back
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    toggle();
  };

  var shortYear = new Date().getFullYear();
  const year = shortYear.toString().substr(-2);

  const amount = order?.invoice?.redeemAmount
    ? calAmount - order?.invoice?.redeemAmount
    : calAmount;

  useEffect(() => {
    setTimeout(() => {
      if (show) toggle();
      console.log("123");
    }, 100);
  }, [show]);

  const renderModal = () => {
    return (
      <>
        <div className="slip section-to-print">
          <div>
            <div>
              <div>
                <div />
                <div className="text_center f_13">
                  <div>
                    <span className="pb_3 f_16" style={{color:"black",fontWeight:"bold",fontSize:28}}>
                      {user?.data?.branch?.name_kh}
                    </span>
                  </div>
                  <div className="invh1" style={{color:"black",fontWeight:"bold"}} >{user?.data?.branch?.name_en}</div>
                  <div style={{color:"black",fontWeight:"bold"}} >{user?.data?.branch?.address}</div>
                  {user?.data?.branch?.license ? (
                    <div style={{color:"black",fontWeight:"bold"}} >{user?.data?.branch?.license}</div>
                  ) : null}
                  <div style={{color:"black",fontWeight:"bold"}} >Tel:{user?.data?.branch?.phone}</div>
                  <div style={{color:"black",fontWeight:"bold"}}  className="invh3 my_2">
                    {user?.data?.branch?.subtitle}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div />
                    <div class="borderline"></div>
                    <span>
                      <table className="w_100">
                        <tbody>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}}>Cashier :</td>
                            <td style={{color:"black",fontWeight:"bold"}}>{user?.data?.name}</td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>Time : </td>
                            <td style={{color:"black",fontWeight:"bold"}} align="right">
                              {order?.invoice?.manualTime ? order?.invoice?.manualTime: new Date().toLocaleTimeString('en-CA', { hour12: true })}
                            </td>
                          </tr>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}}>Customer :</td>
                            <td style={{color:"black",fontWeight:"bold"}}>
                              {customer?.data?.name
                                ? customer?.data?.name
                                : "Walk in"}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>Date :</td>
                            <td style={{color:"black",fontWeight:"bold"}} align="right">
                              {" "}
                              {order?.invoice?.manualDate ? order?.invoice?.manualDate : new Date().toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}}>Invoice No :</td>
                            <td style={{color:"black",fontWeight:"bold"}}>
                              {order?.invoice?.manualInvoiceId ? order?.invoice?.manualInvoiceId  : uniqId}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}}>Shop </td>
                            <td style={{color:"black",fontWeight:"bold"}} align="right">
                              :{user?.data?.branch?.location}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                  <hr className="solid"></hr>
                  <div>
                    <div />
                    <span>
                      <table className="w_100 f_13">
                        <tbody>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}} width={26} className="invh4">
                              No
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} width={136} className="invh4">
                              Description
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} width={26} align="center" className="invh4">
                              Qty
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} width={46} align="center" className="invh4">
                              Price
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} width={46} align="center" className="invh4">
                              Dis.
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} width={56} align="right" className="invh4">
                              Amount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                </div>
              </div>
              <div />
              <hr className="solid"></hr>
              <div className="f_13">
                {order?.products?.map((item, index) => {
                  return (
                    <div>
                      <table className="w_100 ">
                        <tbody>
                          <tr>
                            <td width={26} style={{color:"black",fontWeight:"bold"}}  className="invh4">
                              {index + 1}
                            </td>
                            <td width={136} style={{color:"black",fontWeight:"bold"}} className="invh4">
                            { item?.name_en?.slice(0, 9)}
                            </td>
                            <td width={26} style={{color:"black",fontWeight:"bold"}}  align="center" className="invh4">
                              {item?.quantity}
                            </td>
                            <td width={46} style={{color:"black",fontWeight:"bold"}}  align="center" className="invh4">
                              {n_format(item?.price)}{" "}
                            </td>
                            <td width={46} style={{color:"black",fontWeight:"bold"}}  align="center" className="invh4">
                              ({n_format(item?.discount_percent)}%)
                            </td>
                            <td width={56} style={{color:"black",fontWeight:"bold"}}  align="right" className="invh4">
                              {" "}
                              {n_format(item?.price * item?.quantity - (item?.discount_percent * item?.price * item?.quantity /100))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>

              <hr className="solid"></hr>
              <div>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}}>សរុប ($) Sub Total ($) :</td>
                        <td align="right"  style={{color:"black",fontWeight:"bold"}}  className="invh2">
                          $ {n_format(calAmount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}}>បញ្ចុះតំលៃ Discount ({order.discount}%) :</td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh2">
                         ($ {n_format(orderDiscount)}{" "})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                {customer?.data?.phone && order?.invoice?.redeemAmount ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}}>Redeem Point : </td>
                          <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh2">
                            {order?.invoice?.redeemAmount
                              ? n_format(order?.invoice?.redeemAmount)
                              : "0.00"}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} className="invh3">
                          សរុបចុងក្រោយ ($) Grand Total ($):
                        </td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh3">
                          ${" "}
                          {n_format(
                            calAmount -
                              orderDiscount -
                              (order?.invoice?.redeemAmount ?? 0)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} className="invh3">
                          សរុបចុងក្រោយ (៛) Grand Total (៛):
                        </td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh3">
                          ៛ {n_round((calAmount  -
                              orderDiscount -
                              (order?.invoice?.redeemAmount ?? 0)) * USD_RATE?.value)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >ប្រាក់ទទួល($) PaidAmount ($):</td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh2">
                          $ {n_format(order?.invoice?.dinomAmount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}}>ប្រាក់អាប់ ($) Change ($):</td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh2">
                          ${" "}
                          {n_format(
                            +order?.invoice?.dinomAmount -
                              +calAmount +
                              +orderDiscount +
                              +order?.invoice?.redeemAmount
                          )}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >ប្រាក់អាប់ (៛) Change (៛):</td>
                        <td align="right" style={{color:"black",fontWeight:"bold"}}  className="invh2">
                          ៛{" "}
                          {n_round(n_format(
                            +order?.invoice?.dinomAmount -
                              +calAmount +
                              +orderDiscount +
                              +order?.invoice?.redeemAmount
                          ) * USD_RATE?.value)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >
                          Customer ID :{" "}
                          {customer?.data?.phone
                            ? "***" +
                              customer?.data?.phone
                                ?.toString()
                                ?.slice(
                                  customer?.data?.phone?.toString()?.length - 4
                                )
                            : "Walk in"}
                        </td>
                        {customer?.data?.phone ? (
                          <td align="right" style={{color:"black",fontWeight:"bold"}}  >
                            Customer Name :{" "}
                            {customer?.data?.name
                              ? customer?.data?.name
                              : "Walk in"}
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </span>
                {customer?.data?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}}>Gain Point : {n_format(orderPoints)} </td>
                          <td align="right" style={{color:"black",fontWeight:"bold"}} >
                            Previous Point :{" "}
                            {customer?.data?.point
                              ? n_format(customer?.data?.point)
                              : n_format(0)}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                {customer?.data?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}} >
                            Redeem Point :{" "}
                            {order?.invoice?.redeemAmount
                              ? n_format(order?.invoice?.redeemAmount)
                              : "0.00"}{" "}
                          </td>
                          <td align="right" style={{color:"black",fontWeight:"bold"}} >
                            Total Point :{" "}
                            {customer?.data?.point
                              ? n_format(
                                  customer?.data?.point +
                                    orderPoints -
                                    order?.invoice?.redeemAmount
                                )
                              : n_format(
                                  orderPoints - order?.invoice?.redeemAmount
                                )}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div />
          <div className="text_center arial my_3" style={{color:"black",fontWeight:"bold"}} >
            {user?.data?.branch?.message}
          </div>
          {user?.data?.branch?.wifiname ? (
            <span>
              <table className="w_100 arial">
                <tbody>
                  <tr>
                    <td style={{color:"black",fontWeight:"bold"}} >WiFi :{user?.data?.branch?.wifiname}</td>
                    <td style={{color:"black",fontWeight:"bold"}}  align="justify">
                      Password:{user?.data?.branch?.wifipass}
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          ) : null}
          <span>
            <table className="w_100 arial">
              <tbody>
                <tr>
                  <td style={{ color: "black", fontWeight: "bold",float:"right"  }} >
                    1 $ = {USD_RATE?.value} (៛)

                  </td>
                </tr>
              </tbody>
            </table>
          </span>
        </div>

        <div className="pagebreak"></div>
      </>
    );
  };
  return (
    <CommonModal
      full
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      ok={false}
      header="Change Information"
      title_2="Closed"
      cancel
      toggleModal={back}
      style={{
        display: show ? "block" : "none",
        visibility: "hidden",
      }}
    />
  );
}

export default InvoiceModal;
