import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { compactText, n_format, t } from '../../../utils/common'
import { FaInfoCircle } from 'react-icons/fa'
import Motion1 from '../../../common/components/Motion1'

const ProductRow = ({ item, index = 1, header = false }) => {
	return (
		<>
			<Motion1>
				<ProductItem isHeader={header} item={item}>
					<div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
						<h5 style={{ width: '5%', textAlign: 'center' }}>{item?.item_code}</h5>
						{/* <h5 style={{ width: '10%', textAlign: 'center' }}>{item?.date == 'Last Updated Date' ? item?.date : moment(item?.updated_at).format('D/M/Y')} </h5> */}
						<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.barcode} </h5>
						<h5 style={{ width: '12%', textAlign: 'center' }}>{compactText(item?.name_en, 50)} </h5>
						<h5 style={{ width: '10%', textAlign: 'center' }}>
							{header ? '' : '$'}
							{item?.price == 'Price' ? item?.price : n_format(item?.price)}
						</h5>
						<h5 style={{ width: '12%', textAlign: 'center' }}>
							{!header ? (
								<Link to={`/stock-logs/${item?.id}`} style={{ textDecoration: 'none' }}>
									<Button>
										<FaInfoCircle color="#fff" />
										&nbsp;Show Logs
									</Button>
								</Link>
							) : (
								'Actions'
							)}
						</h5>
					</div>
				</ProductItem>
			</Motion1>
		</>
	)
}

const ProductItem = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: '#fcfcfc';
	margin: 10px 20px;
	justify-content: center;
	/* cursor: pointer; */
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	padding-top: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	padding-bottom: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--red-2);
	text-decoration: none;
	color: white;
	padding: 16px 26px;
	width: fit-content;
	white-space: nowrap;
	margin: 4px;
	border-radius: 4px;
`

export default ProductRow
