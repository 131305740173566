import axios from "axios";


export default {
    
    getCurrencies:async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_TAX_URL_API+'/get_currencies');
            return response.data;
        } catch (error) {
            throw error;
        }
    },

}
