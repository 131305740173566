import React from 'react'
import { useSelector } from 'react-redux'
import Navbar from './components/Navbar'
import ProductRow from './components/ProductRow'
import ProductTable from './components/ProductTable'
import Footer from './components/Footer'
import { t } from '../../utils/common'
import ProductAPI from '../../api/ProductAPI'

function StockViewContainer() {
	// const filteredProducts = products
	// 	.filter((product) => {
	// 		return product.name.toLowerCase().includes(search.toLowerCase())
	// 	})
	// 	.sort((a, b) => {
	// 		return a.name.localeCompare(b.name)
	// 	})
	// 	.map((product) => {
	// 		return <ProductRow key={product.id} product={product} />
	// 	})
	// 	.reverse()

	return (
		<div
			style={{
				marginBottom: '100px',
			}}
		>
			<Navbar title={'Stock Status'} entryType={'manual'} />
			<ProductRow
				header
				item={{
					id: 0,
					item_code: t('Item Code'),
					date: t('Last Updated'),
					barcode: t('Barcode'),
					name_en: t('Name'),
					price: t('Price'),
					is_return: t('Return'),
					stock: t('Stock In'),
					expiry_date: t('Expiry Date'),
				}}
			/>
			<ProductTable />
			<Footer />
		</div>
	)
}

export default StockViewContainer
