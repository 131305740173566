import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import CommonModal from '../../common/components/CommonModal'
import SyncInvoiceService from '../../utils/offline/invoiceApis'
import * as commonActions from '../../state/common/commonActions'
import * as orderActions from '../../state/order/orderActions'
import * as customerActions from '../../state/customer/customerActions'
import * as userActions from '../../state/user/userActions'
import * as suspendActions from '../../state/suspend/suspendActions'
import * as offlineActions from '../../state/offline/offlineActions'
import * as customerScreenActions from '../../state/customerScreen/customerScreenActions'

import { downloadFile, emptyCache, toggleFullScreen, togglePOSFullScreen, useQuery } from '../../utils/common'
import im1 from './../../assets/im1.png'
import { FaArrowLeft, FaSync } from 'react-icons/fa'

function StockManagementContainer() {
	// Confirm Logout
	const [showConfirmLogout, setShowConfirmLogout] = useState(false)
	const [syncing, setSyncing] = useState(false)

	const toggleConfirmLogout = () => setShowConfirmLogout(!showConfirmLogout)

	const dispatch = useDispatch()
	const history = useHistory()

	const offline = useSelector((state) => state.offline)
	// const [countOffline, setCountOffline] = useState(offline?.invoices?.length)

	const onOkConfirmLogout = async () => {
		await syncData()
		dispatch(customerScreenActions.toggleScreen(false))
		dispatch(commonActions.trashOff())
		dispatch(orderActions.emptyOrder())
		dispatch(customerActions.emptyCustomer())
		dispatch(userActions.emptyUser())
		dispatch(suspendActions.emptySuspend())
		history.push('/')
	}

	function openWindow() {
		var width = 5550
		var left = 5600
		var w = 5550
		var h = 5600
		var top = window.screen.height / 2 - h / 2
		var url = 'customer-screen'
		var title = 'Customer Screen'

		left += Math.abs(window.screenX)

		console.log(window)

		window.open(
			'/?screen=cs',
			'windowName',
			'resizable=1,scrollbars=1,fullscreen=0,height=100%,width=' + width + ' , height=' + h + ' , top=' + top + ', left=' + left + ', toolbar=0, menubar=0,status=1'
		)
		return 0
	}

	const syncData = async () => {
		if (offline?.invoices?.length > 0) {
			downloadFile(offline?.invoices)
			setSyncing(true)
			await offline?.invoices.forEach(async (invoice) => {
				const response = await SyncInvoiceService.addOrder(invoice)
				if (response.success) {
					await dispatch(offlineActions.deleteOfflineInvoice(invoice.id))
					// setCountOffline(countOffline-1)
					console.log('Success', invoice)
				} else {
				}
			})
			// setSyncing(false)
			// dispatch(offlineActions.emptyOfflineInvoices())
		}
		if (offline?.customers?.length > 0) {
		}
	}

	return (
		<div className="home-container  bg-10 w-100">
			<div className="modal-header-lg row bg-1 align-center justify-center">
				<h3 style={{ color: 'var(--white)' }}>Operation</h3>
			</div>
			<div className="modal-actions-lg row justify-center">
				<Link to="/home" className="modal-btn-lg row justify-center align-center bg-1 text-1">
					<FaArrowLeft color="#fff" /> &nbsp; Back
				</Link>
			</div>

			<div className="row justify-around align-left mt-2 label">
				<img src={im1} alt="" className="operation-img" />
				<div className="row justify-end" style={{ flexWrap: 'wrap', width: '25%' }}>
					<Link to="/stock-in" className="ml-1 row mt-1 justify-center align-center">
						<img src={process.env.PUBLIC_URL + '/assets/icons/box-in.png'} height="100" width="100" alt="Stock Control" />
					</Link>
					<Link to="/stock-view" className="ml-1 row mt-1 justify-center align-center">
						<img src={process.env.PUBLIC_URL + '/assets/icons/box-out.png'} height="100" width="100" alt="View Stock" />
					</Link>
				</div>
			</div>

			<CommonModal children={() => {}} show={showConfirmLogout} onOk={onOkConfirmLogout} title="Confirm Logout ?" toggleModal={toggleConfirmLogout} />
		</div>
	)
}

export default StockManagementContainer
