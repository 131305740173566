import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import categoryReducer from "./category/categoryReducer";
import commonReducer from "./common/commonReducer";
import customerReducer from "./customer/customerReducer";
import orderReducer from "./order/orderReducer";
import productReducer from "./product/productReducer";
import userReducer from "./user/userReducer";
import suspendReducer from "./suspend/suspendReducer";
import invoiceReducer from "./invoice/invoiceReducer";
import branchReducer from "./branch/branchReducer";


import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import offlineReducer from "./offline/offlineReducer";
import customerScreenReducer from "./customerScreen/customerScreenReducer";
import stockReducer from "./stock/stockReducer";

const persistConfig = {
  key: "root",
  storage,
  // blacklist:['products','common','categories','invoice'],
  stateReconciler: hardSet,
  blacklist: ['stock']
};

const rootReducer = combineReducers({
  products: productReducer,
  common: commonReducer,
  order: orderReducer,
  categories: categoryReducer,
  customer: customerReducer,
  user: userReducer,
  suspend:suspendReducer,
  invoice:invoiceReducer,
  offline:offlineReducer,
  customerScreen:customerScreenReducer,
  stock: stockReducer,
  branches:branchReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

export default store;
