import axios from "axios";

const promotionsAPI = {
  getPromotions: async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_TAX_URL_API + "/promotions");
      return response.data;
    } catch (error) {
      throw error;
    }
  },


};

export default promotionsAPI;
