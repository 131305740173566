import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as stockActions from '../../../state/stock/stockActions'
import ButtonLg from '../../../common/components/ButtonLg'
import MyModal from '../../../common/components/MyModal'
import { t } from '../../../utils/common'
import { useHistory } from 'react-router'
import BottomKeyPad from './BottomKeyPad'

export default function Footer() {
	const dispatch = useDispatch()
	const history = useHistory()
	const [showModal, setShowModal] = useState(false)
	const common = useSelector((state) => state.common)

	// Redux

	// Functions
	const onOk = () => {
		dispatch(stockActions.emptyProducts())
		setShowModal(!showModal)
	}

	return (
		<>
			<div
				style={{
					position: 'fixed',
					bottom: '0',
					width: '100%',
					zIndex: '20',
				}}
			>
				{common?.showkeyboard && <BottomKeyPad />}
				<div className="row">
					<div className="w-40">
						<ButtonLg
							bg="bg-4"
							text="text-1"
							title="Back"
							onClick={() => {
								history.goBack()
							}}
						/>
					</div>
					<div className="w-100">
						{/* <ButtonLg
			onClick={() => {history.goBack()}}
			bg="bg-3"
			text="text-2"
			title="Back"
		/> */}
						<ButtonLg
							onClick={() => {
								history.push('/operation')
								dispatch(stockActions.emptyProducts())
							}}
							bg="bg-3"
							text="text-2"
							title="Operation"
						/>
					</div>
					{/* <div className="w-50">
					<ButtonLg onClick={() => setShowModal(!showModal)} bg="bg-4" text="text-2" title="Clear" />
				</div> */}
				</div>
				<MyModal
					children={() => {}}
					show={showModal}
					onOk={onOk}
					title_1="Ok"
					title_2="Cancel"
					title={t('Confirm Clear  ?')}
					style={{ position: 'fixed', zIndex: 22 }}
					toggleModal={() => setShowModal(!showModal)}
				/>
			</div>
		</>
	)
}
