import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import { n_format, n_round } from "../../../utils/common";
import "../../../invoice.css";

function InvoiceModal({ show, toggle, invoice, reprint = false }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);

  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);

  const dispatch = useDispatch();

  useEffect(() => {
    setOrderAmount();
  }, [order.products, show]);

  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    invoice?.order?.forEach((e) => {
      const oldTotal = n_format(e.quantity) * n_format(e.price);
      const discount =
        (n_format(oldTotal) * n_format(e.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = pureTotalAmount + total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = pureQuantity + e.quantity;
      purePoints = +purePoints + (e.quantity * e.price * (e?.point || 0)) / 100;
    });

    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount));
  };

  const onOk = () => {
    // On CLosed
    back();
  };

  const back = () => {
    // TODO Reset and then back
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    toggle();
  };

  useEffect(() => {
    setTimeout(() => {
      if (show) {
        toggle();
      }
      console.log("123");
    }, 100);
  }, [show]);

  const renderModal = () => {
    return (
      <>
        <div className="slip section-to-print">
          <div>
            <div>
              <div>
                <div />
                <div className="text_center f_13">
                  <div>
                    <span className="pb_3 f_16" style={{ color: "black", fontWeight: "bold", fontSize: 28 }}>
                      {invoice?.branch?.name_kh ||
                        user?.data?.branch?.name_kh}
                    </span>
                  </div>
                  <div style={{ color: "black", fontWeight: "bold" }} className="arial invh3">
                    {invoice?.branch?.name_en || user?.data?.branch?.name_en}
                  </div>
                  <div style={{ color: "black", fontWeight: "bold" }}>
                    {invoice?.branch?.address || user?.data?.branch?.address}
                  </div>
                  {invoice?.branch?.license || user?.data?.branch?.license ? (
                    <div style={{ color: "black", fontWeight: "bold" }}>
                      {invoice?.branch?.license || user?.data?.branch?.license}
                    </div>
                  ) : null}
                  <div style={{ color: "black", fontWeight: "bold" }}>
                    Tel:{invoice?.branch?.phone || user?.data?.branch?.phone}
                  </div>
                  <div style={{ color: "black", fontWeight: "bold" }} className="invh3 my_2">
                    {invoice?.branch?.subtitle || user?.data?.branch?.subtitle}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div />
                    <div class="borderline"></div>
                    <span>
                      <table className="w_100">
                        <tbody>
                          <tr>
                            <td style={{ color: "black", fontWeight: "bold" }} >Cashier </td>
                            <td style={{ color: "black", fontWeight: "bold" }} >:{invoice?.user?.name || user?.data?.name}</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >Time </td>
                            <td style={{ color: "black", fontWeight: "bold" }} align="right">
                              : {" "}
                              {new Date(
                                invoice?.created_at
                              ).toLocaleTimeString('en-CA', { hour12: true })}
                              {/* {invoice?.created_at} */}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "black", fontWeight: "bold" }} >Customer </td>
                            <td style={{ color: "black", fontWeight: "bold" }} >
                              :
                              {invoice?.customer?.name || customer?.data?.name
                                ? invoice?.customer?.name ||
                                customer?.data?.name
                                : "Walk in"}
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >Date</td>
                            <td style={{ color: "black", fontWeight: "bold" }} align="right">
                              {" "}
                              :
                              {new Date(
                                invoice?.created_at
                              ).toLocaleDateString('en-CA', { hour12: true })}
                              {/* {invoice?.created_at} */}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "black", fontWeight: "bold" }} >Invoice No </td>
                            <td style={{ color: "black", fontWeight: "bold" }} >:{invoice?.uniq_id || order?.invoice?.id}</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >&nbsp;</td>
                            <td style={{ color: "black", fontWeight: "bold" }} >Shop </td>
                            <td style={{ color: "black", fontWeight: "bold" }} align="right">
                              :
                              {invoice?.branch?.location ||
                                user?.data?.branch?.location}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                  <hr className="solid"></hr>
                  <div>
                    <div />
                    <span>
                      <table className="w_100 f_13">
                        <tbody>
                          <tr>
                            <td style={{ color: "black", fontWeight: "bold" }} width={26} className="invh4">
                              No
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={136} className="invh4">
                              Description
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={26} align="center" className="invh4">
                              Qty
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={46} align="center" className="invh4">
                              Price
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={46} align="center" className="invh4">
                              Dis.
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={56} align="right" className="invh4">
                              Amount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                </div>
              </div>
              <div />
              <hr className="solid"></hr>
              <div className="f_13">
                {invoice?.order?.map((item, index) => {
                  return (
                    <div>
                      <table className="w_100 ">
                        <tbody>
                          <tr>
                            <td style={{ color: "black", fontWeight: "bold" }} width={26} className="invh4">
                              {index + 1}
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={136} className="invh4">
                              {item?.name_en?.slice(0, 9) ?? item?.product?.name_en?.slice(0, 9)}
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={26} align="center" className="invh4">
                              {item?.quantity}
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={46} align="center" className="invh4">
                              {n_format(item?.price)}{" "}
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={46} align="center" className="invh4">
                              {n_format(item?.discount_percent)}%
                            </td>
                            <td style={{ color: "black", fontWeight: "bold" }} width={56} align="right" className="invh4">
                              {" "}
                              {n_format(item?.price * item?.quantity)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>

              <hr className="solid"></hr>
              <div>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >សរុប ($) Sub Total ($) :</td>
                        <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                          $ {n_format(calAmount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >
                          បញ្ចុះតំលៃ Discount ({invoice?.total_discount}%) :
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                          $
                          {n_format(
                            (+invoice?.total_discount * calAmount) / 100
                          )}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                {invoice?.customer?.phone && invoice?.redeem_amount ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{ color: "black", fontWeight: "bold" }} >Redeem Point : :</td>
                          <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                            {invoice?.redeem_amount
                              ? n_format(invoice?.redeem_amount)
                              : "0.00"}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} className="invh3">
                          សរុបចុងក្រោយ ($) Grand Total ($):
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh3">
                          $ {n_format(invoice?.amount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} className="invh3">
                          សរុបចុងក្រោយ (៛) Grand Total (៛):
                        </td>
                        {reprint ?
                          <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh3">
                            ៛ {n_round(invoice?.amount * parseFloat(invoice?.currency_rate ?? USD_RATE?.value))}
                          </td>
                          :
                          <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh3">
                            ៛ {n_round(invoice?.amount * USD_RATE?.value)}
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >ប្រាក់ទទួល($) PaidAmount ($):</td>
                        <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                          $ {n_format(invoice?.dinom_amount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >ប្រាក់អាប់ ($) Change ($):</td>
                        <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                          ${" "}
                          {n_format(+invoice?.dinom_amount - +invoice?.amount)}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >ប្រាក់អាប់ (៛) Change (៛):</td>
                        {reprint ?
                          <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                            ៛{" "}
                            {n_round(
                              n_format(
                                +invoice?.dinom_amount - +invoice?.amount
                              ) * parseFloat(invoice?.currency_rate ?? USD_RATE?.value)
                            )}
                          </td>
                          :
                          <td style={{ color: "black", fontWeight: "bold" }} align="right" className="invh2">
                            ៛{" "}
                            {n_round(
                              n_format(
                                +invoice?.dinom_amount - +invoice?.amount
                              ) * USD_RATE?.value
                            )}
                          </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100">
                    <tbody>
                      <tr>
                        <td style={{ color: "black", fontWeight: "bold" }} >
                          Customer ID :{" "}
                          {invoice?.customer?.phone
                            ? "***" +
                            invoice?.customer?.phone
                              ?.toString()
                              ?.slice(
                                invoice?.customer?.phone?.toString()?.length -
                                4
                              )
                            : "Walk in"}
                        </td>
                        {invoice?.customer?.phone ? (
                          <td style={{ color: "black", fontWeight: "bold" }} align="right">
                            Customer Name :{" "}
                            {invoice?.customer?.name
                              ? invoice?.customer?.name
                              : "Walk in"}
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </span>
                {invoice?.customer?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{ color: "black", fontWeight: "bold" }} >
                            Gain Point : {n_format(invoice?.order_point)}{" "}
                          </td>
                          <td style={{ color: "black", fontWeight: "bold" }} align="right">
                            Previous Point :{" "}
                            {
                              reprint
                                ?
                                (
                                  invoice?.current_points
                                    ?
                                    n_format(invoice?.current_points)
                                    :
                                    n_format(invoice?.order_point)
                                )
                                :
                                (
                                  invoice?.customer?.point
                                    ? n_format(
                                      invoice?.customer?.point +
                                      invoice?.redeem_amount
                                    )
                                    : n_format(invoice?.order_point)
                                )
                            }{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                {invoice?.customer?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{ color: "black", fontWeight: "bold" }} >
                            Redeem Point :{" "}
                            {invoice?.redeem_amount
                              ? n_format(invoice?.redeem_amount)
                              : "0.00"}{" "}
                          </td>
                          <td style={{ color: "black", fontWeight: "bold" }} align="right">
                            Total Point :{" "}
                            {
                              reprint
                                ?
                                (
                                  invoice?.current_points
                                    ?
                                    n_format(
                                      invoice?.current_points +
                                      invoice?.order_point -
                                      invoice?.redeem_amount
                                    )
                                    :
                                    n_format(
                                      invoice?.order_point - invoice?.redeem_amount
                                    )
                                )
                                :
                                (invoice?.customer?.point
                                  ? n_format(
                                    invoice?.customer?.point +
                                    invoice?.order_point -
                                    invoice?.redeem_amount
                                  )
                                  : n_format(
                                    invoice?.order_point - invoice?.redeem_amount
                                  )
                                )
                            }{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div />
          <div className="text_center arial my_3">
            {invoice?.branch?.message || user?.data?.branch?.message}
          </div>
          {invoice?.branch?.wifiname || user?.data?.branch?.wifiname ? (
            <span>
              <table className="w_100 arial">
                <tbody>
                  <tr>
                    <td style={{ color: "black", fontWeight: "bold" }} >
                      WiFi :
                      {invoice?.branch?.wifiname ||
                        user?.data?.branch?.wifiname}
                    </td>
                    <td style={{ color: "black", fontWeight: "bold" }} align="justify">
                      Password:
                      {invoice?.branch?.wifipass ||
                        user?.data?.branch?.wifipass}
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          ) : null}

          {reprint ? (
            <span>
              <table className="w_100 arial">
                <tbody>
                  <tr>
                    <td style={{ color: "black", fontWeight: "bold" }} >
                      Reprint

                    </td>
                    <td style={{ color: "black", fontWeight: "bold" ,float:"right"}} >
                      1 $ = {invoice?.currency_rate} (៛)

                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          ) : <span>
            <table className="w_100 arial">
              <tbody>
                <tr>
                  <td style={{ color: "black", fontWeight: "bold",float:"right" }} >
                    1 $ = {USD_RATE?.value} (៛)

                  </td>
                </tr>
              </tbody>
            </table>
          </span>}
        </div>

        <div className="pagebreak"></div>
      </>
    );
  };
  return (
    <CommonModal
      full
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      ok={false}
      header="Change Information"
      title_2="Closed"
      cancel
      toggleModal={back}
      style={{
        display: show ? "block" : "none",
        visibility: "hidden",
      }}
    />
  );
}

export default InvoiceModal;
