import axios from 'axios'
import {get, post} from './../utils/api'
import store from './../state/store'
import * as offlineActions from './../state/offline/offlineActions'
import { downloadFile } from '../utils/common'

const orderAPIs = {
	addOrder: async (data) => {
		console.log(data, 'Random Id Data')
		// console.log(store.getState().offline, "snkdjnsdkeffj");
		const cancelTokenSource = axios.CancelToken.source()
		let ok = false
		let data2
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			cancelToken: cancelTokenSource.token,
		}
		try {
			data2 = axios
				.post(process.env.REACT_APP_TAX_URL_API + '/add_order', data, headers)
				.then((res) => {
					ok = true
					console.log(res.data, 'RESPONSE DATA')
					return res.data
				})
				.catch(async function (thrown) {
					if (axios.isCancel(thrown)) {
						console.log('Request canceled', thrown.message)
						// console.log(store.getState().offline, "snkdjnsdkfj");
						downloadFile(data)
						store.dispatch(
							offlineActions.addOfflineInvoice({
								id: store.getState().offline?.invoices?.length + 1,
								data: { ...data, mode: 'S' },
							})
						)
						// store.dispatch(offlineActions.addCheckInvoice({
						//     id: store.getState().offline?.checkInvoices?.length + 1,
						//     data
						// }));
						// return thrown;
						return { success: true }
					} else {
						store.dispatch(
							offlineActions.addOfflineInvoice({
								id: store.getState().offline?.invoices?.length + 1,
								data: { ...data, mode: 'S' },
							})
						)
						return { success: true }
						console.warn(thrown)
					}
				})
			setTimeout(async () => {
				if (!ok || data.success === false) {
					console.log(data, 'dbhjdbjfbdkj')
					cancelTokenSource.cancel('Request Failed Due to Slow Internet , Getting Data from Offline Mode')
				} else {
					return data2
				}
			}, 6000)
			return data2
		} catch (error) {
			throw error
		}
	},
	getOrders: async (params = {}, token) => {
		console.log(params, token)

		try {
			if (navigator.onLine) {
				const response = await get(
					process.env.REACT_APP_TAX_URL_API + '/get-orders',
					{
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + token,
					},
					params
				)
				return response.data
			} else {
			}
		} catch (error) {}
	},
}

export default orderAPIs
