import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Error from './common/components/Error'
import HomePageContainer from './pages/HomePage/HomePageContainer'
import LoginPageContainer from './pages/LoginPage/LoginPageContainer'
import ReprintPageContainer from './pages/ReprintPage/ReprintPageContainer'
import CustomerPageContainer from './pages/CustomerPage/CustomerPageContainer'
import ProductSearchContainer from './pages/ProductSearchPage/ProductSearchContainer'
import ReturnPageContainer from './pages/ReturnPage/ReturePageContainer'
import OperationPageContainer from './pages/OperationPage/OperationPageContainer'
import ManualReturnPageContainer from './pages/ManualReturnPage/ManualReturnPageContainer'
import SignOffPageContainer from './pages/SignOffPage/SignOffPageContainer'
import StockManagementContainer from './pages/StockManagement/StockManagementContainer'
import StockInContainer from './pages/StockIn/StockInContainer'
import StockViewContainer from './pages/StockView/StockViewContainer'
import StockLogsContainer from './pages/StockLogs/StockLogContainer'

export default function AppRouter() {
	const user = useSelector((state) => state.user)

	return (
		<Router>
			<div>
				<Switch>
					<Route path="/home" exact>
						<HomePageContainer />
					</Route>
					<Route path="/" exact>
						<LoginPageContainer />
					</Route>
					<Route path="/reprint" exact>
						<ReprintPageContainer />
					</Route>
					<Route path="/product-search" exact>
						<ProductSearchContainer />
					</Route>
					<Route path="/customer-screen" exact>
						<CustomerPageContainer />
					</Route>
					<Route path="/return" exact>
						<ReturnPageContainer />
					</Route>
					<Route path="/stock-management" exact>
						<StockManagementContainer />
					</Route>
					<Route path="/stock-in" exact>
						<StockInContainer />
					</Route>
					<Route path="/stock-view" exact>
						<StockViewContainer />
					</Route>
					<Route path="/stock-logs/:product_id" exact>
						<StockLogsContainer />
					</Route>
					<Route path="/return/:option" exact>
						<ManualReturnPageContainer />
					</Route>
					<Route path="/operation" exact>
						<OperationPageContainer />
					</Route>
					<Route path="/sales-report" exact>
						<SignOffPageContainer />
					</Route>
					<Route path="/error">
						<Error />
					</Route>
				</Switch>
			</div>
		</Router>
	)
}
