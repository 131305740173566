import React from 'react'
import { useDispatch } from 'react-redux';
import * as invoiceActions from "../../../state/invoice/invoiceActions";
import { getTens, n_format } from "../../../utils/common";

function TableRow({e,selected,setSelected,setReturnInvoice, partialInvoiceReturn}) {
const dispatch = useDispatch();
const showReturnQuick = !(e?.return_invoice?.length > 0);
const showReturnManual = !(e?.return_invoice[0]?.amount == e?.amount);
// console.log();


    return (
      <div className="border-all mt-1 mb-1">   
      <div className={`row ${selected?.id === e?.id ? 'selected' :''}`} onClick={() =>{
        setSelected(e);
        dispatch(invoiceActions.setActiveInvoice(e));
        

      }}>
       <div className="w-60">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{e.uniq_id}</span>
        </div>
      </div>

      <div className="w-100 ">
        <div className="btn t-header p-1 row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{new Intl.DateTimeFormat('en-CA', { 
                dateStyle: 'short', timeStyle: 'short'
            }).format(new Date(e.created_at))}  </span>
        </div>
      </div>
    
      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">${n_format(e.amount)}</span>
        </div>
      </div>
     
      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{e?.customer_id ? e.order_point?? "-":"-"}</span>
        </div>
      </div>
   
      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4">{e?.customer_id ? e.redeem_amount??"-":"-"}</span>
        </div>
      </div>
      <div className="w-60 " onClick={() => {
        if(showReturnQuick){
          setReturnInvoice(e);
          dispatch(invoiceActions.setActiveInvoice(e));
        }
          
      }}>
        <div className={`btn ${showReturnQuick? "bg-1" :"bg-5"} t-header row justify-center align-center t-left border-bg-4 `} style={{width:"90%"}} >
          <span className="text-1" style={{fontSize:12}}>Quick Return</span>
        </div>
      </div>

      <div className="w-60 " onClick={() => {
        console.log(e?.return_invoice?.length);
          if(showReturnManual){
        partialInvoiceReturn(e);
          }
      }}>
        <div className={`btn ${showReturnManual ? "bg-6" :"bg-5"} t-header row justify-center align-center t-left border-bg-4 `} style={{width:"90%"}} >
          <span className="text-1" style={{fontSize:12}}>Manual Return</span>
        </div>
      </div>

      </div>
    </div>
    )
}

export default TableRow
