import React from 'react'
import { useDispatch } from 'react-redux';
import * as invoiceActions from "../../../state/invoice/invoiceActions";
import { n_format } from "../../../utils/common";

function TableRow({e,selected,setSelected}) {
const dispatch = useDispatch();


    return (
      <div className="border-all ">   
      <div className={`row ${selected?.id === e?.id ? 'selected' :''}`} onClick={() =>{
        setSelected(e);
        dispatch(invoiceActions.setActiveInvoice(e))

      }}>
      <div className="w-60">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{e.uniq_id}</span>
        </div>
      </div>
      
      <div className="w-100 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{new Intl.DateTimeFormat('en-CA', { 
                 dateStyle: 'short', timeStyle: 'short'
            }).format(new Date(e.created_at))}  </span>
        </div>
      </div>
     
      <div className="w-30 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 "> $ {n_format(e.amount)}</span> 
        </div>
      </div>

      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4">{e?.customer_id ? e.order_point: "-"}</span>
        </div>
      </div>
    
      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{e?.customer_id ? e.redeem_amount??"-" :"-"}</span>
        </div>
      </div>
   
      <div className="w-60 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-4 ">{e?.customer?.name || "-"}</span>
        </div>
      </div>
   
     
      </div>
    </div>
    )
}

export default TableRow
