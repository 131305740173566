import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import StockService from '../../../api/StockAPI'
import ProductRow from './ProductRow'

const ProductTable = ({ product_id }) => {
	const dispatch = useDispatch()
	const [productWithLogs, setProductWithLogs] = React.useState([])
	const [loading, setLoading] = React.useState(true)
	const [search, setSearch] = React.useState('')
	const user = useSelector((state) => state.user)

	const fetchProducts = async (query) => {
		setLoading(true)
		const response = await StockService.getProductWithLogs(product_id)
		console.log({ response })
		if (!response?.id) return
		setProductWithLogs(response)
		setLoading(false)
	}
	React.useEffect(() => {
		fetchProducts()
	}, [])
	
	 const name = productWithLogs.name_en;
	return (
		<div>
			{productWithLogs?.logs?.length > 0 ? (
				<>
					{productWithLogs?.logs
						?.map((item, index) => {
							return (
								<ProductRow
									isHistory={true}
									key={item.id}
									item={item }
									name={name}
								/>
							)
						})
						.reverse()}
				</>
			) : (
				<h2 style={{ textAlign: 'center', margin: 20 }}>No Product Found</h2>
			)}
		</div>
	)
}

const ProductDiv = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: #ecfc91;
	margin: 0px 20px;
	justify-content: center;
	/* cursor: pointer; */
	margin-top: 10px;
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const styles = {
	input: { height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 },
}

export default ProductTable
