import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import {useSelector } from "react-redux";
import { n_format, n_round } from "../../../utils/common";
import "../../../invoice.css";
import InvoiceService from "../../../api/InvoiceAPI";


function InvoiceModal({ show, toggle,completeData }) {
 
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const invoice = useSelector((state) => state.invoice);

  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [orderPoints, setOrderPoints] = useState(0.0);
  const [uniqId, setUniqId] = useState("")



  useEffect(() => {
    setOrderAmount();
  }, []);

  const setOrderAmount = async() => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    completeData?.order?.products?.forEach((e) => {
      const oldTotal = n_format(e.quantity) * n_format(e.price);
      const discount =
        (n_format(oldTotal) * n_format(e.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = pureTotalAmount + total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = pureQuantity + e.quantity;
      purePoints = +purePoints + (e.quantity * e.price * (e?.point || 0)) / 100;
    });

    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount));
    const invoiceId =invoice?.lastInvoiceId;
    setUniqId(invoiceId)
  };

  const onOk = () => {
    // On CLosed
    back();
  };

  const back = () => {
   
    toggle();
  };

 

  useEffect(() => {
   
    setTimeout(() => {
      console.log(completeData,"8989898989");
      if (show){ 
       // window.print();
          toggle();
        
        };
      console.log("123");
    }, 100);
  }, [show]);

 

  const renderModal = () => {
    return (
      <>
        <div className="slip section-to-print">
          <div>
            <div>
              <div>
                <div />
                <div className="text_center f_13">
                  <div>
                    <span className="pb_3 f_16" style={{color:"black",fontWeight:"bold",fontSize:28}}>
                      {completeData?.order?.invoice?.branch?.name_kh }
                    </span>
                  </div>
                  <div style={{color:"black",fontWeight:"bold"}} className="arial invh3">
                    {completeData?.order?.invoice?.branch?.name_en }
                  </div>
                  <div style={{color:"black",fontWeight:"bold"}}>{completeData?.order?.invoice?.branch?.address}</div>
                  {completeData?.order?.invoice?.branch?.license  ? (
                    <div style={{color:"black",fontWeight:"bold"}}>{completeData?.order?.invoice?.branch?.license}</div>
                  ) : null}
                  <div style={{color:"black",fontWeight:"bold"}}>Tel:{completeData?.order?.invoice?.branch?.phone}</div>
                  <div style={{color:"black",fontWeight:"bold"}} className="invh3 my_2">
                    {completeData?.order?.invoice?.branch?.subtitle}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div />
                    <div class="borderline"></div>
                    <span>
                      <table className="w_100">
                        <tbody>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}} >Cashier </td>
                            <td style={{color:"black",fontWeight:"bold"}} >:{completeData?.order?.invoice?.user?.name }</td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >Time </td>
                            <td style={{color:"black",fontWeight:"bold"}}  align="right">
                              :{new Date().toLocaleTimeString()}
                            </td>
                          </tr>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}} >Customer </td>
                            <td style={{color:"black",fontWeight:"bold"}} >
                              :
                              {completeData?.customer?.data?.name 
                                ? completeData?.customer?.data?.name 
                                : "Walk in"}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >Date</td>
                            <td style={{color:"black",fontWeight:"bold"}}  align="right">
                              {" "}
                              :{new Date().toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}} >Invoice No </td>
                            <td style={{color:"black",fontWeight:"bold"}} >
                              {/* TODO 1 */}
                              :{uniqId}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >&nbsp;</td>
                            <td style={{color:"black",fontWeight:"bold"}} >Shop </td>
                            <td style={{color:"black",fontWeight:"bold"}}  align="right">
                              :{completeData?.order?.invoice?.branch?.location }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                  <hr className="solid"></hr>
                  <div>
                    <div />
                    <span>
                      <table className="w_100 f_13">
                        <tbody>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}}  width={26} className="invh4">
                              No.
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={136} className="invh4">
                              Description
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={26} align="center" className="invh4">
                              Qty
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={46} align="center" className="invh4">
                              Price
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={46} align="center" className="invh4">
                              Dis.
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={56} align="right" className="invh4">
                              Amount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                </div>
              </div>
              <div />
              <hr className="solid"></hr>
              <div className="f_13">
                {completeData?.order?.products?.map((item, index) => {
                  return (
                    <div>
                      <table className="w_100 ">
                        <tbody>
                          <tr>
                            <td style={{color:"black",fontWeight:"bold"}}  width={26} className="invh4">
                              {index + 1}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={136} className="invh4">
                            { item?.name_en?.slice(0, 9) ??  item?.product?.name_en?.slice(0, 9)} 
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={26} align="center" className="invh4">
                              {item?.quantity}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={46} align="center" className="invh4">
                              {n_format(item?.price)}{" "}
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={46} align="center" className="invh4">
                              ({n_format(item?.discount_percent)}%)
                            </td>
                            <td style={{color:"black",fontWeight:"bold"}}  width={56} align="right" className="invh4">
                              {" "}
                              ({n_format(item?.price * item?.quantity - (item?.discount_percent * item?.price * item?.quantity /100))})
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>

              <hr className="solid"></hr>
              <div>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >សរុប ($) Sub Total ($) :</td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh2">
                         ($ {n_format(completeData?.amountToReturn)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >បញ្ចុះតំលៃ Discount ({completeData?.order?.invoice?.total_amount}$) :</td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh2">
                         $({n_format(completeData?.discountReturn)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                {completeData?.customer?.data?.phone && completeData?.order?.invoice?.redeem_amount ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}} >Redeem Point : </td>
                          <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh2">
                            {completeData?.order?.invoice?.redeem_amount
                              ? n_format(completeData?.order?.invoice?.redeem_amount)
                              : "0.00"}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}}  className="invh3">
                          សរុបចុងក្រោយ ($) Grand Total ($):
                        </td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh3">
                         ($ {n_format(completeData?.grandTotalReturn)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}}  className="invh3">
                          សរុបចុងក្រោយ (៛) Grand Total (៛):
                        </td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh3">
                         (៛ {n_round((completeData?.grandTotalReturn ) * parseFloat(completeData?.order?.invoice?.currency_rate ?? USD_RATE?.value))})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >ប្រាក់ទទួល($) Paid Amount ($):</td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh2">
                         ($ {n_format(completeData?.grandTotalReturn)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                {/* <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >ប្រាក់អាប់ (៛) Change (៛):</td>
                        <td style={{color:"black",fontWeight:"bold"}}  align="right" className="invh2">
                          ៛{" "}
                          {n_format(
                           completeData?.grandTotalReturn
                          ) * USD_RATE?.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span> */}
                <span>
                  <table className="w_100">
                    <tbody>
                      <tr>
                        <td style={{color:"black",fontWeight:"bold"}} >
                          Customer ID :{" "}
                          {completeData?.customer?.data?.phone
                            ? "***" +
                            completeData?.customer?.data?.phone 
                            ?.toString()
                                ?.slice(
                                  completeData?.customer?.data?.phone?.toString()?.length - 4
                                )
                          
                                
                            : "Walk in"}
                        </td>
                        {completeData?.customer?.data?.phone ? (
                          <td style={{color:"black",fontWeight:"bold"}}  align="right">
                            Customer Name :{" "}
                            {completeData?.customer?.data?.name
                              ? completeData?.customer?.data?.name
                              : "Walk in"}
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </span>
                {completeData?.customer?.data?.phone? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}} >Return Point : ({n_format(completeData?.returnPoints)}) </td>
                          <td style={{color:"black",fontWeight:"bold"}}  align="right">
                            Previous Point :{" "}
                              {completeData?.customer?.data?.point
                              ? n_format(completeData?.order?.invoice?.customer?.point)
                              : n_format(completeData?.returnPoints)}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                {/* RECHECK TODO
                  Check their Redeem points on return partial Invoice
                */}
                {completeData?.customer?.data?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td style={{color:"black",fontWeight:"bold"}} >
                            Redeem Point :{" "}
                            {completeData?.order?.invoice?.redeem_amount
                              ? n_format(completeData?.order?.invoice?.redeem_amount)
                              : "0.00"}{" "}
                          </td>
                          <td style={{color:"black",fontWeight:"bold"}}  align="right">
                            Total Point :{" "}
                            {completeData?.customer?.data?.point
                              ? n_format(
                                completeData?.order?.invoice?.customer?.point +
                                -completeData?.order?.invoice?.order_point +
                                completeData?.order?.invoice?.redeem_amount
                                )
                              : n_format(
                                completeData?.order?.invoice?.order_point -  completeData?.order?.invoice?.redeem_amount
                                )}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div />
          <div className="text_center arial my_3">
            {completeData?.order?.invoice?.branch?.message}
          </div>
          {completeData?.order?.invoice?.branch?.wifiname ? (
            <span>
              <table className="w_100 arial">
                <tbody>
                  <tr>
                    <td style={{color:"black",fontWeight:"bold"}} >WiFi :{completeData?.order?.invoice?.branch?.wifiname}</td>
                    <td style={{color:"black",fontWeight:"bold"}}  align="justify">
                      Password:{completeData?.order?.invoice?.branch?.wifipass }
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          ) : null}
          <span>
            <table className="w_100 arial">
              <tbody>
                <tr>
                  <td style={{ color: "black", fontWeight: "bold",float:"right"  }} >
                    1 $ = {completeData?.order?.invoice?.currency_rate} (៛)

                  </td>
                </tr>
              </tbody>
            </table>
          </span>
        </div>

        <div className="pagebreak"></div>
      </>
    );
  };
  return (
    <CommonModal
      full
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      ok={false}
      header="Change Information"
      title_2="Closed"
      cancel
      toggleModal={back}
      style={{
        display: show ? "block" : "none",
        visibility:"hidden"
      }}
    />
  );
}

export default InvoiceModal;
