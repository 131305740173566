import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import StockService from '../../../api/StockAPI'
import * as commonActions from '../../../state/common/commonActions'
import { compactText, n_format, t } from '../../../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import Motion1 from '../../../common/components/Motion1'
import moment from 'moment'

const ProductRow = ({ setFocusedInput, item, index = 1, header, isHistory = false, isReturn = false, showModal, setShowModal, setSelectedItem, getProducts,setIsAddStock }) => {
	const [addStockText, setAddStockText] = useState(t('Add Stock'))
	const [editText, setEditText] = useState(t('Edit'))
	const [oldStock, setOldStock] = useState(item?.stock)
	const user = useSelector((state) => state.user)
	const [done, setDone] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().substr(0, 10))
	const [dateEdit, setDateEdit] = useState(item?.expiry_date)
	const [disable, setDisable] = useState(false)
	const [isReturnState, setIsReturnState] = useState('N')
	const [isReturnEditState, setIsReturnEditState] = useState(item?.is_return)

	const [stock, setStock] = useState((item?.stock && item?.entry_type) || '')
	const common = useSelector((state) => state.common)
	const dispatch = useDispatch()

	const setSearchTextFromVK = () => common.activeField == 'SM_stock' + item?.id && setStock(common.text)

	useEffect(() => {
		setSearchTextFromVK()
	}, [common.activeField, common.text])


	const onEdit = async () => {
		if (!isEdit) {
			// setStockEdit(item?.stock)
			setIsEdit(!isEdit)
			setEditText('Save')
			dispatch(commonActions?.setActiveField('SM_stock' + item?.id))
			dispatch(commonActions.setText(item?.stock))
			return
		}

		if (parseInt(stock) >= 0 || dateEdit != item?.expiry_date) {
			dispatch(commonActions.setLoading(true))
			try {
				const response = await StockService.updateStock(
					{
						id: item?.stock_id,
						stock: stock,
						entry_type: item?.entry_type,
						expiry_date: dateEdit,
						is_return: isReturnEditState
					},
					user?.data?.api_token
				)
				dispatch(commonActions.setLoading(false))
				if (response?.success) {
					setIsEdit(!isEdit)
					setEditText('Edit')
					getProducts(item?.barcode)
				} 
			} catch (error) {
				dispatch(commonActions.setLoading(false))
				console.log(error)
			}
		} else {
			dispatch(commonActions.setLoading(false))
			alert('Enter Stock Please')
			return
		}
	}
	const onStockOut = (is_add_stock) => {
		setIsAddStock(is_add_stock)
		setShowModal(true)
		setSelectedItem(item)
	}


	useEffect(() => {
		setDone(false)
		setAddStockText(t('Add Stock'))
		setOldStock(item?.stock)
	}, [item])

	return (
		<>
			<Motion1>
				<ProductItem done={done} isHeader={header} isHistory={isHistory} isReturn={isReturn} item={item}>
					<div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
						<h5 style={{ width: '5%', textAlign: 'center' }}>{item?.item_code}</h5>
						{/* <h5 style={{ width: '10%', textAlign: 'center' }}>{item?.date == 'Last Updated Date' ? item?.date : moment(item?.updated_at).format('D/M/Y')} </h5> */}
						<h5 style={{ width: '10%', textAlign: 'center' }}>{item?.barcode} </h5>
						<h5 style={{ width: '12%', textAlign: 'center' }}>{compactText(item?.name_en, 50)} </h5>
						<h5 style={{ width: '10%', textAlign: 'center' }}>
							{header ? '' : '$'}
							{item?.price == 'Price' ? item?.price : n_format(item?.price)}
						</h5>
						
						<h5 style={{ width: '8%', textAlign: 'right' }}>
						{item?.stock}	
						</h5>
						
						{isHistory ? (
							<>
								<h4 style={{ width: '15%', display: 'flex' }}>
									{item?.entry_type === 'in' ? (
										<>
											<Button color="#025169" onClick={onEdit}>
												<h4 style={{ color: '#fff' }}>{editText}</h4>
											</Button>
											<Button color="#7D542A" onClick={onStockOut}>
												<h4 style={{ color: '#fff' }}>Stock Out</h4>
											</Button>
										</>
									) : null}
								</h4>
							</>
						) : header ? (
							<h4 style={{ width: '15%', textAlign: 'center' }}>Action</h4>
						) : (
							<div style={{ width: '15%', textAlign: 'center' }}>
								{disable == false ? (
									<>
									<h4 style={{ display: 'flex' }}>
										
												<Button color="#7D542A" onClick={()=>onStockOut(false)}>
													<h4 style={{ color: '#fff' }}>Stock Out</h4>
												</Button>
												<Button color="#000" onClick={()=>onStockOut(true)}>
										<h4 style={{ color: '#fff' }}>{addStockText}</h4>
									</Button>
								
									</h4>
								</>
									
								) : (
									<Button color="#000">
										<h4 style={{ color: '#fff' }}>Wait Moment..</h4>
									</Button>
								)}
							</div>
						)}
					</div>
				</ProductItem>
			</Motion1>
		</>
	)
}

export default ProductRow

const ProductItem = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: ${({ isHistory, item, done, isHeader, stockAdd, isReturn }) =>
		isHistory ? (item?.entry_type === 'out' ? '#ffcccb' : '#87f8c9') : done ? '#ecfc91' : isHeader ? '#fcfcfc' : '#fcf5fa'};
	margin: 10px 20px;
	justify-content: center;
	/* cursor: pointer; */
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	margin-bottom: ${({ isHistory, isHeader }) => (!isHistory && !isHeader ? '80px' : ' unset')};
	padding-top: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	padding-bottom: ${({ isHeader }) => (isHeader ? '5px' : '10px')};
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const Button = styled.div`
	cursor: pointer;
	background-color: ${({ color }) => color};
	padding: 10px 15px;
	border-radius: 4px;
	margin-right: 10px;
	border: 2px solid ${({ color }) => color};

	&:hover {
		border: 2px solid white;
	}
`
