import React from 'react'

function ProductItem({item,onClick}) {
    return (
        <div className="product-item" onClick={onClick}>
            <img src={process.env.REACT_APP_TAX_URL_IMAGE+"/product/"+item.image} alt="" srcset=""/>
            <div className="text-1 product-title">{ item?.name_en?.slice(0, 5)}</div>
        </div>
    )
}

export default ProductItem
