import MyModal from '../../../common/components/MyModal'
import React, { useEffect, useState, Style } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductRow from './ProductRow'
import StockService from '../../../api/StockAPI'
import ProductService from '../../../api/ProductAPI'
import { t } from '../../../utils/common'
import * as stockActions from '../../../state/stock/stockActions'
import * as commonActions from '../../../state/common/commonActions'
import styled from 'styled-components'
import BranchAPI from '../../../api/BranchAPI'
import { setBranches } from '../../../state/branch/branchActions'

const ProductTable = ({ entryType = 'in' }) => {
	// States
	const stockOutDefault = {
		stock: null,
		entry_type: 'out',
		expiry_date: new Date().toISOString().substr(0, 10),
		is_return: 'N',
		stock_out_reason: null,
	}
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)
	const [stockOutItem, setStockOutItem] = useState(stockOutDefault)
	const [showStockOutReasonText, setShowStockOutReasonText] = useState(false)
	const [otherReason, setOtherReason] = useState('')
	const [isAddStock, setIsAddStock] = useState(true)
	const [isShowBranch, setIsShowBranch] = useState(false)

	// Redux
	const stock = useSelector((state) => state.stock)
	const user = useSelector((state) => state.user)
	const branches = useSelector((state) => state.branches)
	const dispatch = useDispatch()

	useEffect(() => {
		setStockOutItem((state) => ({
			...state,
			stock_data:{stock: selectedItem?.stock_data?.stock},
			product_id: selectedItem?.id,
		}))
		
	}, [selectedItem])

	const getAllBrancher = async () => {
		if (branches && branches.length > 0) return
		let response = await BranchAPI.getBranches()
		if (response?.length > 0) {
			dispatch(setBranches(response))
		}
	}

	useEffect(() => {
		getAllBrancher()
	}, [])
	// Functions
	// confirm Modal

	const onOk = () => {
		handleStockOut()
	}


	const handleStockOut = async () => {
		dispatch(commonActions.setLoading(true))

		if(Number(stockOutItem?.stock) == 0  ) {
			alert('Stock is mandatory');
			return false;
		}
			if(stockOutItem?.to_branch == ""  ) {
				alert('Branch is mandatory');
				return false;
			}
		try {
			const response = await StockService.stockOut(
				{
					...stockOutItem,
					user_id: user?.data?.id,
					stock_out_reason: !stockOutItem?.stock_out_reason == 'other' ? otherReason : stockOutItem?.stock_out_reason,
					from_branch: user?.data?.branch_id,
					to_branch: stockOutItem?.to_branch,
					product_id:selectedItem?.id,
					is_add_stock:isAddStock
				},
				user?.data?.api_token
			)
			if (response?.success) {
				setShowModal(!showModal)
				setStockOutItem({
					...stockOutDefault
				})
				getProducts(selectedItem?.barcode)
			} else {
			
			}
			setShowModal(!showModal)
		} catch (error) {
			console.log(error)
		} finally {
			dispatch(commonActions.setLoading(false))
		}
	}


	const showKeyboard = (show = null) => {
    dispatch(commonActions.toggleKeyboard(show));
  };


	const getProducts = async (barcode) => {
		dispatch(commonActions.setLoading(true))
		try {
			var branch_id = user?.data?.branch_id;
			const product = await ProductService.scanProduct({ barcode, branch_id })
			dispatch(commonActions.setLoading(false))
			if (product.data?.length > 0) {
				dispatch(stockActions.addProduct(product.data[0]))
			}
		} catch (error) {
			dispatch(commonActions.setLoading(false))
			console.warn(error)
		}
	}
	const handleStockOutReason = (e) => {
		
		if(e.target.value == "transfer_to_branch") {
			setIsShowBranch(true);
		} else{
			setIsShowBranch(false);
		}
	
		setStockOutItem((state) => ({ ...state, stock_out_reason: e.target.value }))
		if (e.target.value === 'other') {
			setShowStockOutReasonText(false)
			setOtherReason('');
		} else {
			
			setShowStockOutReasonText(true)
		}
	}


	// JSX
	return (
		<div>
			{stock.products?.length > 0 ? (
				<>
					
					<ProductRow getProducts={getProducts}
											showModal={showModal}
											isReturn={stock.products[0].is_return == 'Y'}
											setShowModal={setShowModal}
											setSelectedItem={setSelectedItem}
											isEdit={true}
											key={stock.products[0].id.toString()}
											entryType={entryType}
											setIsAddStock={setIsAddStock}
											item={{
												...stock.products[0],
												// logs: stock.products[0]?.logs,
												...stock.products[0],
												id: stock.products[0]?.id,
												stock_product: stock.products[0]?.stock_data?.stock,
												stock: stock.products[0]?.stock_data?.stock,
												stock_id: stock.products[0]?.id,
												is_return: stock.products[0]?.is_return == 'Y' ? 'Y' : 'N',
												expiry_date: stock.products[0]?.expiry_date,
												updated_at: stock.products[0]?.updated_at,
											}} 
											
											/>
					<ProductRow
						header
						item={{
							id: 0,
							item_code: t('Item Code'),
							date: t('Last Updated'),
							barcode: t('Barcode'),
							name_en: t('Name'),
							price: t('Price'),
							stock:t('Stock in'),
							is_return: t('Return'),
							expiry_date: t('Expiry Date'),
						}}
					/>

					{stock.products[0]?.logs?.length > 0
						? stock.products[0]?.logs
								?.map((stock_item, index) => {
									return (
										<ProductRow
											getProducts={getProducts}
											showModal={showModal}
											isReturn={stock_item.is_return == 'Y'}
											setShowModal={setShowModal}
											setSelectedItem={setSelectedItem}
											isHistory={true}
											key={stock_item.id.toString()}
											entryType={entryType}
											item={{
												...stock.products[0],
												// logs: stock_item?.logs,
												...stock_item,
												id: stock_item?.id,
												stock_product: stock.products[0]?.stock,
												stock: stock_item?.stock,
												stock_id: stock_item?.id,
												is_return: stock_item?.is_return == 'Y' ? 'Y' : 'N',
												expiry_date: stock_item?.expiry_date,
												updated_at: stock_item?.updated_at,
											}}
										/>
									)
								})
								.reverse()
						: null}
				</>
			) : (
				<h2 style={{ textAlign: 'center', margin: 20 }}>No Product Found</h2>
			)}

			{showModal && <MyModal
				children={() => {
					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<h4 style={{ textAlign: 'left', marginTop: 4 , color:'#ffffff' }}>{isAddStock ? 'Stock Quantity' : 'Stock Out'}</h4>
							<input
								type="number"
								placeholder={isAddStock ? 'Enter Quantity' :`Max ${selectedItem?.stock_data?.stock}`}
								style={styles.input}
								max={selectedItem?.stock_data?.stock}
								value={stockOutItem?.stock}
								min={1}
								onChange={(e) => {
									if (e.target.value > selectedItem?.stock_data?.stock) {
										setStockOutItem((state) => ({ ...state, stock: selectedItem?.stock_data?.stock }))
										return
									}
									setStockOutItem((state) => ({ ...state, stock: e.target.value }))
								}}
							/>
							{/* <h4 style={{ textAlign: 'left', margin: 3 , color:'#ffffff' }}>Add Stock</h4>
							<input type="date" style={styles.input} value={stockOutItem?.expiry_date} onChange={(e) => setStockOutItem((state) => ({ ...state, expiry_date: e.target.value }))} /> */}
						{!isAddStock && 
						<>
						<h4 style={{ textAlign: 'left', marginTop: 4 , color:'#ffffff' }}>Select Reason</h4>
							<select style={styles.input} onChange={handleStockOutReason}>
								{[
									{
										name: 'Select reason',
										value: '',
									},
									{
										name: 'Transfer to Branch',
										value: 'transfer_to_branch',
									},
									{
										name: 'Sales',
										value: 'sales',
									},
									{
										name: 'Expired',
										value: 'expired',
									},
									{
										name: 'Broken',
										value: 'broken',
									},
									
									{
										name: 'Other',
										value: 'other',
									},
								].map((e) => {
									return (
										<option value={e.value} key={e.value} selected={e.value == stockOutItem.stock_out_reason}>
											{e.name}
										</option>
									)
								})}
							</select>
							
							{isShowBranch ? <><h4 style={{ textAlign: 'left', marginTop: 4 , color:'#ffffff' }}>To Branch</h4>
										<select style={styles.input} onChange={(e) => setStockOutItem((state) => ({ ...state, to_branch: e.target.value }))} >
											<option value="">Select branch</option>
											{branches.map((e) => {
													if (user?.data?.branch_id !== e.id) {
												return (	
													<option value={e.id} key={e.id}>
														{e.name_en}
													</option>
												
												)
											}	})}
										</select></> : ""}

							
							</>
				}
							<h4 style={{ textAlign: 'left', marginTop: 4 , color:'#ffffff' }}>Detail (Optional)</h4>
							<textarea
								style={{ ...styles.input, height: 'unset' }}
								cols="30"
								rows="5"
								placeholder="Enter restaurant/customer info"
								maxLength={240}
								onChange={(e) => setStockOutItem((state) => ({ ...state, send_to_info: e.target.value }))}
							>
								{stockOutItem?.stock_out_info}
							</textarea>
							
						</div>
					)
				}}
				show={showModal}
				onOk={onOk}
				title_1={isAddStock ? 'Add Stock' : "Stock Out"}
				title_2="Cancel"
				title={t('Confirm stock out?')}
				style={{ position: 'fixed', zIndex: 22 }}
				handleCancel={() => setShowModal(false)}
				handleOk={handleStockOut}
				toggleModal={() => {
					setStockOutItem(stockOutDefault)
					showKeyboard()
					setShowModal(!showModal)
				}}
			/>}
		</div>
	)
}

const ProductDiv = styled.div`
	padding: 10px 0px 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: #ecfc91;
	margin: 0px 20px;
	justify-content: center;
	/* cursor: pointer; */
	margin-top: 10px;
	border: 1px solid #000;
	/* margin-bottom:${({ isHeader }) => (isHeader ? 'unset' : ' 15px')};*/
	border-width: ${({ isHeader }) => (isHeader ? '1px' : '1px')};
	&:hover {
		opacity: 0.6;
	}
`

const styles = {
	input: { height: 30, margin: 'auto', fontSize: 17, width: '100%', paddingLeft: 10, marginTop: 10 },
}

export default ProductTable
