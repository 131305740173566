import React from 'react'
import { Pagination } from 'react-laravel-paginex'
const LaravelPagination = ({ ...props }) => {
	return (
		<Pagination
			options={{
				containerClass: 'pagination-container',
				prevButtonClass: 'page-item',
				nextButtonText: 'Next',
				buttonIcons: false,
				prevButtonText: 'Prev',
				prevButtonIcon: 'fa fa-chevron-left',
				nextButtonClass: 'page-item',
				nextButtonText: 'Next',
				nextButtonIcon: 'fa fa-chevron-right',
				numberButtonClass: 'page-item',
				numberClass: 'page-link',
				numbersCountForShow: '2',
				activeClass: 'active',
			}}
			{...props}
		/>
	)
}
export default LaravelPagination
