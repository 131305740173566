import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import im1 from "./../../assets/im1.png";
import * as userActions from "../../state/user/userActions";
import * as orderActions from "../../state/order/orderActions";
import * as commonActions from "../../state/common/commonActions";
import * as customerScreenActions from "../../state/customerScreen/customerScreenActions";
import * as customerActions from "../../state/customer/customerActions";
import * as invoiceActions from "../../state/invoice/invoiceActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../api/UserAPI";
import { useHistory, useLocation } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import layouts, { defaultName } from "../../utils/keyboardLayouts";
import ButtonSm from "../../common/components/ButtonSm";
import {
  FaCross,
  FaWindowClose,
  FaWindowMaximize,
  FaWindowMinimize,
} from "react-icons/fa";
import { emptyCache, toggleFullScreen, togglePOSFullScreen, useQuery } from "../../utils/common";
import * as Hangul from "hangul-js";
import Motion1 from "../../common/components/Motion1";
import { updateScreens } from "../../utils/multiscreen";
import InvoiceService from "../../api/InvoiceAPI";


function LoginPageContainer() {
  // Login States

  const query = useQuery();
  const fsRef = useRef(null)
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeField, setActiveField] = useState("u");
  const [window2, setWindow2] = useState(null);
  const [ed, setEd] = useState(true);

  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const customerScreen = useSelector((state) => state.customerScreen);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
   
  
    if (!common?.cache) {
      emptyCache();
      dispatch(commonActions?.clearCache());
    }
    const screen = query.get("screen");
    console.log(screen);
    if (screen && screen == "cs") {
      history.push("/customer-screen");
    } else if (user?.data) {
     
      // dispatch(customerScreenActions.toggleScreen(true));
      // var width = 1890;
      // var left = 1500;
      // var w = 1250;
      // var h = 1600;
      // var top = window.screen.height / 2 - h / 2;
      // var url = "customer-screen";
      // var title = "Customer Screen";
      // left += Math.abs(window.screenX);
      // window.open(
      //   "?screen=cs",
      //   "windowName",
      //   "resizable=yes,scrollbars=yes,fullscreen=yes,height=100%,width=" +
      //     width +
      //     " , height=" +
      //     h +
      //     " , top=0,left=0, toolbar=0, menubar=0,status=1"
      // );
      history.push("/home");
    } else{
      // dispatch(customerScreenActions.toggleScreen(true));
      // var width = 1890;
      // var left = 1500;
      // var w = 1250;
      // var h = 1600;
      // var top = window.screen.height / 2 - h / 2;
      // var url = "customer-screen";
      // var title = "Customer Screen";
      // left += Math.abs(window.screenX);
      // window.open(
      //   "?screen=cs",
      //   "windowName",
      //   "resizable=yes,scrollbars=yes,fullscreen=yes,height=100%,width=" +
      //     width +
      //     " , height=" +
      //     h +
      //     " , top=0,left=0, toolbar=0, menubar=0,status=1"
      // );
    }
  }, []);
  

  const login = async () => {
    
    //  togglePOSFullScreen(true);
     const data = await UserService.signIn(name, password);
    
      if (data.success) {
      const uniq_id = await InvoiceService.getInvoiceCode({branch:data?.data?.branch?.id,user_id:data?.data?.id});
      const r_uniq_id = await InvoiceService.getInvoiceCode({branch:data?.data?.branch?.id,returnI:"1",user_id:data?.data?.id});
      console.log(r_uniq_id,"9989797998");
        
        
      dispatch(invoiceActions.setLatestInvoiceId(uniq_id.data));
      dispatch(invoiceActions.setLatestRInvoiceId(r_uniq_id.data));
      dispatch(userActions.emptyUser());
      dispatch(orderActions.emptyOrder());
      dispatch(userActions?.addUser(data?.data));
      dispatch(orderActions.setUser(data?.data?.id));
      dispatch(commonActions.trashOff());
      dispatch(customerActions.emptyCustomer());
      dispatch(suspendActions.emptySuspend());
      console.log(data.data.id);
     
      history.push("/home");
      //toggleFullScreen();
      
      
     
      setErrorMessage("");
    } else {
      setErrorMessage("Incorrect Credentials");
    }
  };

 

  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default");

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };

  return (
    <div className="login-container">
      <div className="login-block">
        <div className="row h-100 justify-around align-center w-100">
          <div className="login-left bg-1 column ">
            <h1>Kim Mart POS</h1>
            <img src={im1} alt="" className="welcome-img" />
          </div>
          <div className="login-form-container">
            <div className="login-form w-100">
              <div className="login-title mb-1">Login</div>
              <div className="login-subtitle mb-1">
                Please Fill your information below
              </div>
              {errorMessage ? <small>{errorMessage}</small> : null}
              <div
                className="login-input-control w-100"
                style={{ position: "relative" }}
              >
                <input
                  type="email"
                  value={name}
                  onClick={() => {
                    setActiveField("u");
                  }}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="USERNAME"
                  className={`login-input w-100 ${
                    activeField === "u" ? "active-field" : ""
                  }`}
                />
                <div
                  onClick={() => {
                    setActiveField("u");
                  }}
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: 0,
                  }}
                ></div>
              </div>
              <div
                className="login-input-control w-100"
                style={{ position: "relative" }}
              >
                <input
                  type="password"
                  onClick={() => setActiveField("p")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="PASSWORD"
                  className={`login-input w-100 ${
                    activeField === "p" ? "active-field" : ""
                  }`}
                />
                <div
                  onClick={() => setActiveField("p")}
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: 0,
                  }}
                ></div>
              </div>
              {/* <div className="row justify-between">
                <div className="remember-me mt-1">
                  <input type="checkbox" name="" id="" /> Remember Me
                </div>
                <div className=" forgot-password mt-1">Forgot Password ?</div>
              </div> */}
              <div className="login-btn" onClick={login}>
                Login
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="keyboard">
        <Motion1>
          <Keyboard
            layout={klayouts[lang]}
            display={defaultName}
            layoutName={layoutName}
            onKeyPress={(e) => {
              if (e === "{caps}") {
                setLayoutName(layoutName === "default" ? "shift" : "default");
                return;
              }

              if (activeField === "u") {
                if (e === "{enter}") {
                  setActiveField("p");
                  return;
                }
                if (e === "{space}") {
                  setName(name + " ");
                  return;
                }
                if (e === "{tab}") {
                  setName(name + "        ");
                  return;
                }
                if (e === "{lang}") {
                  toggleLang();
                  return;
                }
                if (e === "{clear}") {
                  setName("");
                  return;
                }

                if (e === "{bksp}") {
                  if (name.length > 0) {
                    setName(name?.slice(0, -1));
                    return;
                  }
                  return;
                }
                if (lang === 1) {
                  // console.log(text+""+e);
                  let parsed = Hangul.disassemble(name + "" + e);
                  console.log(parsed);
                  console.log(
                    Hangul.assemble(["ㄲ", "ㅑ", "ㅈ"]),
                    "99u9j9j98i"
                  );
                  setName(Hangul.assemble(parsed));
                  // assemble characters
                  // setValue(Hangul.assemble(parsed));
                } else {
                  setName(name + "" + e);
                }
              } else {
                if (e === "{enter}") {
                  login();
                  return;
                }

                if (e === "{space}") {
                  setPassword(password + " ");
                  return;
                }
                if (e === "{tab}") {
                  setPassword(password + "        ");
                  return;
                }
                if (e === "{lang}") {
                  toggleLang();
                  return;
                }
                if (e === "{clear}") {
                  setPassword("");
                  return;
                }

                if (e === "{bksp}") {
                  if (password.length > 0) {
                    setPassword(password?.slice(0, -1));
                    return;
                  }
                  return;
                }

                setPassword(password + "" + e);
              }
            }}
          />
        </Motion1>
      </div>
      <div style={{ position: "absolute", bottom: 10, left: "45%" }}>
        Version 1.0.3
      </div>
      <div  style={{
          color: "#fff",
          height: "40px",
          width: "50px",
          position: "absolute",
          top: 10,
          right:80,
          background: "#E5E5E5",
        }}>
            <div
              onClick={() =>
              { 
              // togglePOSFullScreen(true)
              dispatch(customerScreenActions.toggleScreen(true));
              var width = 1890;
              var left = 1500;
              var w = 1250;
              var h = 1600;
              var top = window.screen.height / 2 - h / 2;
              var url = "customer-screen";
              var title = "Customer Screen";
              left += Math.abs(window.screenX);
              window.open(
                "/?screen=cs",
                "windowName",
                "resizable=yes,scrollbars=yes,fullscreen=yes,height=100%,width=" +
                  width +
                  " , height=" +
                  h +
                  " , top=0,left=0, toolbar=0, menubar=0,status=1"
              );
              }
            }
              className="row justify-center align-center"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/customerscreen.png"}
                height="50"
                width="50"
              />
            </div>
          </div>
         
      <ButtonSm
      ref={fsRef}
        onClick={() => {
          // dispatch(customerScreenActions.toggleScreen(false));
          // togglePOSFullScreen(false);
        }}
        icon={<FaWindowMaximize color="red" size={50} />}
        style={{
          color: "#fff",
          height: "50px",
          width: "50px",
          position: "absolute",
          top: 10,
          right: 10,
          background: "#E5E5E5",
        }}
      />
    </div>
  );
}

export default LoginPageContainer;
