import { get, post } from '../utils/api'

const apis = {
	addStock: async (data, token) => {
		try {
			if (navigator.onLine) {
				const response = await post(process.env.REACT_APP_TAX_URL_API + '/add-stock', data, {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
				})
				return response.data
			} else {
			}
		} catch (error) {}
	},

	stockOut: async (data, token) => {
		try {
			if (navigator.onLine) {
				const response = await post(process.env.REACT_APP_TAX_URL_API + '/stockOutProduct', data, {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
				})
				return response.data
			} else {
			}
		} catch (error) {}
	},

	updateStock: async (data, token) => {
		try {
			if (navigator.onLine) {
				const response = await post(process.env.REACT_APP_TAX_URL_API + '/update-stock', data, {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
				})
				return response.data
			} else {
			}
		} catch (error) {}
	},

	// removeStock: async (data, token) => {
	// 	try {
	// 		if (navigator.onLine) {
	// 			const response = await post(process.env.REACT_APP_TAX_URL_API + '/remove-stock', data, {
	// 				'Content-Type': 'application/json',
	// 				Accept: 'application/json',
	// 				Authorization: 'Bearer ' + token,
	// 			})
	// 			return response.data
	// 		} else {
	// 		}
	// 	} catch (error) {}
	// },
	// returnStock: async (data, token) => {
	// 	try {
	// 		if (navigator.onLine) {
	// 			const response = await post(process.env.REACT_APP_TAX_URL_API + '/return-stock', data, {
	// 				'Content-Type': 'application/json',
	// 				Accept: 'application/json',
	// 				Authorization: 'Bearer ' + token,
	// 			})
	// 			return response.data
	// 		} else {
	// 		}
	// 	} catch (error) {}
	// },

	getStocks: async (params = {}, token) => {
		console.log(params, token)

		try {
			if (navigator.onLine) {
				const response = await get(
					process.env.REACT_APP_TAX_URL_API + '/get-stocks',
					{
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + token,
					},
					params
				)
				return response.data
			} else {
			}
		} catch (error) {}
	},
	getLogProducts: async (params = {}, token) => {
		console.log(params, token)
		try {
			if (navigator.onLine) {
				const response = await get(
					process.env.REACT_APP_TAX_URL_API + '/get-log-products',
					{
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + token,
					},
					params
				)
				return response.data
			} else {
			}
		} catch (error) {}
	},
	getProductWithLogs: async (product_id, params = {}) => {
		console.log(params, product_id)
		try {
			if (navigator.onLine) {
				const response = await get(
					process.env.REACT_APP_TAX_URL_API + '/get-product-with-logs/' + product_id,
					{
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					params
				)
				return response.data
			} else {
			}
		} catch (error) {}
	},
}

export default apis
